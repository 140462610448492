import {
  SHOW_CATEGORY_MODAL,
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  EDIT_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  CANCEL_CATEGORY_MODAL,
} from '../types';

// each reducer has it's own state
export const initialState = {
  storeCategories: [],
  error: null,
  loading: false,
  collapse: false,
  deleteCategory: null,
  updateCategory: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CATEGORY_MODAL:
      return {
        ...state,
        collapse: action.payload,
      };
    case GET_CATEGORIES:
    case ADD_CATEGORY:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        storeCategories: [...state.storeCategories, action.payload],
        collapse: false,
      };
    case ADD_CATEGORY_ERROR:
    case GET_CATEGORIES_ERROR:
    case DELETE_CATEGORY_ERROR:
    case UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CANCEL_CATEGORY_MODAL:
      return {
        ...state,
        loading: action.payload,
        error: action.payload,
        deleteCategory: null,
        updateCategory: null,
        collapse: action.payload,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        storeCategories: action.payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        deleteCategory: action.payload,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        storeCategories: state.storeCategories.filter(category => category.id !== state.deleteCategory),
        deleteCategory: null,
      };
    case EDIT_CATEGORY:
      return {
        ...state,
        updateCategory: action.payload,
      };
    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: null,
        storeCategories: state.storeCategories.map(c => (c.id === action.payload.id ? action.payload : c)),
        collapse: false,
      };
    default:
      return state;
  }
};
