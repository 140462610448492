import React from 'react';
import { Drawer } from 'antd';
import { isMobile } from 'react-device-detect';
import SettingsFooter from '../components/dashboard/settings/SettingsFooter';
import Settings from '../components/dashboard/settings/Settings';

const Menu = ({ onClose, visible, isLoading, handleProfileSettings, storefront, setStorefront }) => {
  return (
    <Drawer
      title={storefront?.Name}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={isMobile ? '90%' : 640}
      bodyStyle={{ paddingBottom: 80 }}
      footer={<SettingsFooter onClose={onClose} isLoading={isLoading} handleProfileSettings={handleProfileSettings} />}
    >
      <Settings storefront={storefront} setStorefront={setStorefront} handleProfileSettings={handleProfileSettings} />
    </Drawer>
  );
};

export default Menu;
