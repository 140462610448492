import { useMutation } from '@apollo/client';
import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { loader } from 'graphql.macro';
import OrdersFlowTable from './OrdersFlowTable';
import withRedirect from '../../../hoc/withRedirect';
import AppContext from '../../../context/Context';
import { setFromDate, setToDate } from '../../../actions/settingsActions';
import { trackWithSegment } from 'helpers/analytics';
import { useSocket } from 'hooks';
import NewOrderNotification from './NewOrderNotification';
import AssignPickerNotification from './AssignPickerNotification';
import PanelContext from 'context/PanelContext';
import { getPickersByBusiness } from 'queries/strapi/pickers';
import { getBaseApiUrl } from '../../../helpers/globals';
import clientAxios from '../../../config/axios';

const updateStoreOrderMutation = loader('queries/graphql/storeOrders/updateStoreOrder.graphql');
const updateDeviceQuery = loader('queries/graphql/devices/updatePrintDevice.graphql');

const Orders = ({ setRedirect, setRedirectUrl, loading, hasError }) => {
  const { storefront, token } = useContext(AppContext);
  const { pusherChannel } = useSocket();
  const [updateOrder] = useMutation(updateStoreOrderMutation);
  const [updateDevice] = useMutation(updateDeviceQuery);

  const { setTitle } = useContext(PanelContext);
  setTitle('Centro de pedidos');

  const [qs] = useState(null);
  const [deliveryType] = useState();
  const [status] = useState();
  const [pickers, setPickers] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [hasPickers, setHasPickers] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentPicker, setCurrentPicker] = useState(null);

  const redirectIfNotAuth = () => {
    if (!token) {
      setRedirectUrl(`/auth/login`);
      setRedirect(true);
    }
  };

  const sendToCloudPrint = async () => {
    const sendPrint = currentOrder;

    if (sendPrint) {
      let store_printer = '';
      if (sendPrint.store?.print_device?.id) {
        //the printer is assigned to the store
        store_printer = sendPrint.store.print_device.id;
      } else if (sendPrint.store?.print_device) {
        store_printer = sendPrint.store.print_device;
      } else if (sendPrint.business?.print_device?.id) {
        //the printer is assigned to the business
        store_printer = sendPrint.business.print_device.id;
      } else if (sendPrint.business?.print_device) {
        //if the data comes from knex, the structure is different
        store_printer = sendPrint.business.print_device;
      } else {
        //no printer asssigned
      }

      if (store_printer !== '') {
        await updateDevice({
          variables: {
            id: store_printer,
            deviceData: {
              Printing: 2,
              PrintingData: sendPrint,
            },
          },
        });
      }
    }
  };

  const trackEvent = () => {
    trackWithSegment({
      event: 'Orders Viewed',
      storefront,
    });
  };

  const trackSearchedOrders = () => {
    if (qs?.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        trackWithSegment({ event: 'Order Searched', storefront });
      }, 3000);
      return () => clearTimeout(delayDebounceFn);
    }
  };

  const listenNewOrders = () => {
    const handleNewOrderEvent = async ({ storeOrderId }) => {
      const { data: storeOrder } = await clientAxios.get(`${getBaseApiUrl()}/store-orders/${storeOrderId}`);
      setCurrentOrder(storeOrder);
      setIsVisible(true);
    };

    if (pusherChannel) {
      pusherChannel.bind('addStoreOrder', handleNewOrderEvent);
    }

    if (pusherChannel) {
      return () => {
        pusherChannel.unbind('addStoreOrder', handleNewOrderEvent);
      };
    } else {
      return () => {};
    }
    // Remove socket on component unmount
  };

  const handleNewOrderNotificationClose = () => {
    setIsVisible(false);
  };

  const handleNewOrderNotificationAction = async () => {
    await updateOrder({
      variables: {
        id: currentOrder.id,
        storeOrder: {
          status: 'processing',
        },
      },
    }).catch(console.error);
    setIsVisible(false);
    sendToCloudPrint();
    if (pickers?.length > 0) {
      setHasPickers(true);
    }
  };

  const handlePickerSelectedAction = async picker => {
    setCurrentPicker(picker);
    await updateOrder({
      variables: {
        id: currentOrder?.id,
        storeOrder: {
          picker,
        },
      },
    }).catch(console.error);
    setHasPickers(false);
    setCurrentPicker(null);
  };

  useEffect(() => {
    const getPickers = async () => {
      if (token) {
        const _pickers = await getPickersByBusiness(storefront?.id, token);
        setPickers(_pickers);
      }
    };
    getPickers();
  }, [storefront, token]);

  useEffect(trackSearchedOrders, [qs, storefront]);
  useEffect(trackEvent, [storefront]);
  useEffect(redirectIfNotAuth, [setRedirect, setRedirectUrl, token]);
  useEffect(listenNewOrders, [pusherChannel]);

  return (
    <div className="p-0">
      <OrdersFlowTable
        loading={loading}
        qs={qs}
        status={status}
        deliveryType={deliveryType}
        hasError={hasError}
        pickers={pickers}
        setHasPickers={setHasPickers}
        setCurrentOrder={setCurrentOrder}
        currentOrder={currentOrder}
      />
      <NewOrderNotification
        isVisible={isVisible}
        onClose={handleNewOrderNotificationClose}
        onStart={handleNewOrderNotificationAction}
        order={currentOrder}
      />
      <AssignPickerNotification
        isVisible={hasPickers}
        onSelect={handlePickerSelectedAction}
        order={currentOrder}
        pickers={pickers}
        selectedPicker={currentPicker}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.storeOrders.loading,
  hasError: state.storeOrders.error,
});

const mapDispatchToProps = dispatch => ({
  setFromDate: fromDate => dispatch(setFromDate(fromDate)),
  setToDate: toDate => dispatch(setToDate(toDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRedirect(Orders));
