import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Typography } from 'ui';
import { InputNumber } from 'antd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled(InputNumber)`
  width: 100%;
  .ant-input-number-input-wrap input {
    text-align: center;
    font-size: 2rem;
    height: 48px;
  }
`;

const ChangeOrderTotal = ({ placeholder, value, onChange, onFocus, onBlur }, ref) => {
  // TODO: Make this configurable
  const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

  return (
    <Container>
      <Typography.Text strong className="mb-4">
        EDITAR PRECIO FINAL
      </Typography.Text>
      <Input
        ref={ref}
        formatter={value => (value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
        size="large"
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={currencyFormatter(placeholder)}
      />
    </Container>
  );
};

export default forwardRef(ChangeOrderTotal);
