import React, { useEffect } from 'react';
import { Avatar, Checkbox, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import { trackWithSegment } from 'helpers/analytics';
import withRedirect from '../../../hoc/withRedirect';

const Settings = ({ storefront, setStorefront, handleProfileSettings }) => {
  const trackEvent = () => {
    trackWithSegment({
      event: 'Profile Viewed',
      storefront,
    });
  };

  useEffect(trackEvent, []);
  //TODO: Fix hook to use redirect instead of href

  return (
    <>
      <Form layout="vertical" onFinish={handleProfileSettings}>
        <Row gutter={24}>
          <Col lg={3}>
            <Avatar size={64} src={storefront.logo && storefront.logo.url} />
          </Col>
          <Col lg={21}>
            <Form.Item label="Nombre">
              <Input
                value={storefront.Name || ''}
                onChange={e => setStorefront({ ...storefront, ...{ Name: e.target.value } })}
              />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item label="Whatsapp">
              <Input
                value={storefront.whatsapp || ''}
                onChange={e => setStorefront({ ...storefront, ...{ whatsapp: e.target.value } })}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item label="Correo electrónico">
              <Input
                value={storefront.email || ''}
                onChange={e => setStorefront({ ...storefront, ...{ email: e.target.value } })}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item label="Color principal">
              <Input
                value={storefront.mainColor || ''}
                onChange={e => setStorefront({ ...storefront, ...{ mainColor: e.target.value } })}
              />
            </Form.Item>
          </Col>

          <Col lg={24}></Col>
          <Col lg={12}>
            <Form.Item label="Calculo de costo de envio">
              <Select
                defaultValue={storefront.shippingCostType || 'static'}
                onChange={e => setStorefront({ ...storefront, ...{ shippingCostType: e } })}
              >
                <Select.Option key="static" value="static">
                  Fijo
                </Select.Option>
                <Select.Option key="dynamic" value="dynamic">
                  Dinámico
                </Select.Option>
                <Select.Option key="area" value="area">
                  Por area
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12}>
            {storefront.shippingCostType === 'static' && (
              <Form.Item label="Costo de envío">
                <Input
                  value={storefront.shippingCost || ''}
                  onChange={e => setStorefront({ ...storefront, ...{ shippingCost: e.target.value } })}
                  disabled={!storefront.hasShippingCost}
                />
              </Form.Item>
            )}
          </Col>
          <Col lg={24}>
            <Form.Item label="Configura tus horarios de entrega">
              <a href={`/slots`} target="_blank" rel="noopener noreferrer">
                Horarios
              </a>
            </Form.Item>
          </Col>
          <Divider />
          <Col lg={8}>
            <Checkbox
              checked={storefront.allowCash || false}
              onChange={e => setStorefront({ ...storefront, ...{ allowCash: e.target.checked } })}
            >
              Pago en efectivo
            </Checkbox>
          </Col>
          <Col lg={8}>
            <Checkbox
              checked={storefront.allowTransfer || false}
              onChange={e => setStorefront({ ...storefront, ...{ allowTransfer: e.target.checked } })}
            >
              Transferencia
            </Checkbox>
          </Col>
          <Col lg={8}>
            <Checkbox
              checked={storefront.allowCreditCard || false}
              onChange={e => setStorefront({ ...storefront, ...{ allowCreditCard: e.target.checked } })}
            >
              Pago con tarjeta
            </Checkbox>
          </Col>
          <Col lg={8}>
            <Checkbox
              checked={storefront.allow_coupon_on_delivery || false}
              onChange={e => setStorefront({ ...storefront, ...{ allow_coupon_on_delivery: e.target.checked } })}
            >
              Vales de despensa
            </Checkbox>
          </Col>
          <Col lg={12}>
            <Checkbox
              checked={storefront.allow_card_on_delivery || false}
              onChange={e => setStorefront({ ...storefront, ...{ allow_card_on_delivery: e.target.checked } })}
            >
              Terminal contra entrega
            </Checkbox>
          </Col>
          <Col lg={24} className="mt-4">
            <Form.Item label="Clabe Interbancaria">
              <Input
                value={storefront.clabe || ''}
                disabled={!storefront.allowTransfer}
                placeholder="Escribe los 18 dígitos de tu CLABE interbancaria"
                onChange={e => setStorefront({ ...storefront, ...{ clabe: e.target.value } })}
              />
            </Form.Item>
          </Col>
          <Divider />
          <Col lg={12}>
            <Form.Item label="Compra minima">
              <Input
                value={storefront.minimumPurchase || ''}
                placeholder="Compra minima"
                onChange={e => setStorefront({ ...storefront, ...{ minimumPurchase: e.target.value } })}
              />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item label="Metodo de pago preferido">
              <Select
                defaultValue="cash"
                onChange={e => setStorefront({ ...storefront, ...{ primaryPaymentMethod: e } })}
              >
                <Select.Option key="cash" value="cash">
                  Efectivo
                </Select.Option>
                <Select.Option key="card" value="card">
                  Tarjeta
                </Select.Option>
                <Select.Option key="transfer" value="transfer">
                  Transferencia
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Form.Item label="Dominio de mi tienda en linea">
              <Typography.Title level={5}>
                <a href={`https://${storefront.domain}`} target="_blank" rel="noopener noreferrer">
                  https://{storefront.domain}
                </a>
              </Typography.Title>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withRedirect(Settings);
