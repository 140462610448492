import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Button, Layout, Row } from 'antd';
import React, { useContext } from 'react';
import styled from 'styled-components';
import AppContext from 'context/Context';

const Header = styled(Layout.Header)`
  padding: 0px 24px !important;
`;

const NavbarTop = ({ visible, setVisible, onCollapseSider }) => {
  const { userRoleType } = useContext(AppContext);

  return (
    <Header className="bg-clear-gray">
      <Row align="middle" justify="space-between" style={{ height: '100%' }}>
        <Button type="text" icon={<MenuOutlined />} onClick={onCollapseSider} style={{ fontSize: 22 }} />
        {userRoleType &&
          (userRoleType !== 'storemanager' ||
            userRoleType !== 'storeoperator' ||
            userRoleType !== 'operationsmanager') && (
            <Button type="link" onClick={() => setVisible(!visible)}>
              <Avatar size={30} icon={<UserOutlined />} />
            </Button>
          )}
      </Row>
    </Header>
  );
};

export default NavbarTop;
