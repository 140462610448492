import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { HomeFilled } from '@ant-design/icons';

const Error500 = () => (
  <Result
    status="500"
    title="Whoops, something went wrong!"
    subTitle={
      <p>
        Try refreshing the page, or going back and attempting the action again. If this problem persists,
        <a href="mailto:info@exmaple.com" className="ml-1">
          contact us
        </a>
        .
      </p>
    }
    extra={
      <Link to="/">
        <Button type="primary" icon={<HomeFilled />} size="large">
          Ir a Inicio
        </Button>
      </Link>
    }
  />
);

export default Error500;
