import React from 'react';
import { useQuery } from '@apollo/client';
import Loading from '../states/Loading';

const Query = ({
  children,
  query,
  variables,
  options,
  pollInterval,
  allowLoading = true,
  allowErrors,
  fetchPolicy,
}) => {
  const { data, loading, error, refetch } = useQuery(
    query,
    Object.assign({ variables }, { pollInterval }, { fetchPolicy }, { ...options }),
  );

  if (allowLoading && loading) return <Loading />;
  if (allowErrors && error) return <p>Error: {JSON.stringify(error)}</p>;
  return children ? children({ data }, { loading, error }, refetch) : null;
};

export default Query;
