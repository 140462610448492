import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Result } from 'antd';
import { HomeFilled } from '@ant-design/icons';

const Error404 = () => (
  <Result
    className="my-auto"
    status="404"
    title="La página que está buscando no se encuentra"
    subTitle={
      <p>
        Asegúrese de que la dirección sea correcta y que la página no se haya movido. Si crees que esto es un error
        <a href="mailto:amigos@orchata.com" className="ml-1">
          Contáctanos
        </a>
        .
      </p>
    }
    extra={
      <Link to="/">
        <Button type="primary" icon={<HomeFilled />} size="large">
          Ir a Inicio
        </Button>
      </Link>
    }
  />
);

export default Error404;
