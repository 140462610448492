// import React from 'react';
import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { getButtonBackgroundColor, getButtonBorderColor, getButtonTextColor } from '../../theme/helpers';

const Button = styled(AntButton)`
  color: ${getButtonTextColor} !important;
  background: ${getButtonBackgroundColor} !important;
  border-color: ${getButtonBorderColor} !important;
`;

export default Button;
