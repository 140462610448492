import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import CardSummary from './CardSummary';

import { Col, PageHeader, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { hash } from 'rsvp';
import AppContext from '../../context/Context';
import { getStoreOrdersCount, getStoreRevenue, getStoreCustomers, getKpis } from '../../queries/strapi/dashboard';
import withRedirect from '../../hoc/withRedirect';
import { setFromDate, setToDate } from '../../actions/settingsActions';
import DateRangePicker from '../common/DateRangePicker';
import { trackWithSegment } from 'helpers/analytics';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Dashboard = ({ setRedirect, setRedirectUrl, fromDate, toDate, setFromDate, setToDate }) => {
  
  // State
  const { isLoggedIn, storefront, token, currentStore, currentCity, userRoleType } = useContext(AppContext);
  const [revenue, setRevenue] = useState(0);
  const [kpi, setKpi] = useState({edited_orders: 0, late_deliveries: 0, new_customers: 0, reviews: { avg_order_score: 0, avg_shipping_score: 0}, aov: { total: 0, subtotal: 0 }, discounts: 0, discounts_per: 0, items_per_order: 0, avg_picking: 0});
  const [clientsCount, setClientsCount] = useState(0);
  const [storeOrdersCount, setStoreOrdersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (
        !!storefront.id &&
        token &&
        (((userRoleType === 'storemanager' ||
          userRoleType === 'storeoperator' ||
          userRoleType === 'operationsmanager') &&
          !!currentStore) ||
          userRoleType !== 'storemanager' ||
          userRoleType !== 'storeoperator' ||
          userRoleType !== 'operationsmanager')
      ) {
        const { count, storeRevenue, storeCustomers, kpis } = await hash({
          count: getStoreOrdersCount(storefront.id, token, fromDate, toDate, currentStore, currentCity),
          storeRevenue: getStoreRevenue(storefront.id, token, fromDate, toDate, currentStore, currentCity),
          storeCustomers: getStoreCustomers(storefront.id, token, fromDate, toDate, currentStore, currentCity),
          kpis: getKpis(storefront.id, token, fromDate, toDate, currentStore, currentCity),
        });

        setIsLoading(false);

        if (count) setStoreOrdersCount(count);
        if (storeRevenue) setRevenue(storeRevenue);
        if (storeCustomers) setClientsCount(storeCustomers);
        if (kpis) setKpi(kpis);
      }
    })();
  }, [storefront.id, token, currentStore, userRoleType, fromDate, toDate, currentCity]);

  const redirectIfNotAuth = () => {
    if (!isLoggedIn) {
      setRedirectUrl(`/auth/login`);
      setRedirect(true);
    }
  };

  useEffect(redirectIfNotAuth, []);

  const trackPageView = () => {
    if (storefront?.id) {
      trackWithSegment({
        event: 'Dashboard Viewed',
        storefront: storefront,
      });
    }
  };

  useEffect(trackPageView, []);

  const onFocusCallback = () => {
    trackWithSegment({
      event: 'Dashboard Date Filter Clicked',
      storefront: storefront,
    });
  };

  const onFilterCallback = () => {
    trackWithSegment({
      event: 'Dashboard Date Filtered',
      storefront: storefront,
    });
  };

  const topColResponsiveProps = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 6,
    xl: 6,
    style: {
      marginBottom: 12,
    },
  };

  return (
    <div className="bg-clear-gray">
      <PageHeader
        title="Metricas orchata"
        className="px-0"
        extra={
          <>
            <Spin className={`${isLoading ? 'inline' : 'hidden'}`} indicator={antIcon} />
            <DateRangePicker
              toDate={toDate}
              setToDate={setToDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              onFocusCallback={onFocusCallback}
              onFilterCallback={onFilterCallback}
            />
          </>
        }
      />
      <Row gutter={12}>
        <Col {...topColResponsiveProps}>
          <CardSummary title="Ventas" total={revenue} />
        </Col>
        <Col {...topColResponsiveProps}>
          <CardSummary title="Pedidos" total={storeOrdersCount} hideSymbol />
        </Col>
        <Col {...topColResponsiveProps}>
          <CardSummary title="Clientes" total={clientsCount} hideSymbol />
        </Col>
      {
        storefront.id === 217 &&
        <>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Pedidos Tarde" total={kpi.late_deliveries} hideSymbol/>
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Clientes Nuevos" total={kpi.new_customers} hideSymbol />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Ticket Promedio c/ Desc." total={kpi.aov.total} />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Ticket Promedio s/ Desc." total={kpi.aov.subtotal} />
          </Col>
          {/* <Col {...topColResponsiveProps}>
            <CardSummary title="Pedidos Quemados" total={kpi.reviews.avg_shipping_score} hideSymbol />
          </Col> */}
          <Col {...topColResponsiveProps}>
            <CardSummary title="$ Descuentos" total={kpi.discounts} />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="% Descuentos de lo pagado" total={kpi.discounts_per} hideSymbol suffix='%' />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Articulos por pedido" total={kpi.items_per_order} hideSymbol />
          </Col>
          {/* <Col {...topColResponsiveProps}>
            <CardSummary title="Unidades por pedido" total={kpi.reviews.avg_shipping_score} hideSymbol />
          </Col> */}
          <Col {...topColResponsiveProps}>
            <CardSummary title="Pedidos incompletos" total={kpi.edited_orders} hideSymbol />
          </Col>
          {/* <Col {...topColResponsiveProps}>
            <CardSummary title="% Crecimiento vs Semana anterior" total={kpi.reviews.avg_shipping_score} hideSymbol />
          </Col> */}
          <Col {...topColResponsiveProps}>
            <CardSummary title="Tiempo picking promedio (mins)" total={kpi.avg_picking} hideSymbol />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Calificación por pedido" total={kpi.reviews.avg_order_score} hideSymbol decimals={2} />
          </Col>
          <Col {...topColResponsiveProps}>
            <CardSummary title="Calificación por entrega" total={kpi.reviews.avg_shipping_score} hideSymbol decimals={2} />
          </Col>
        </>
      }
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  fromDate: state.settings.fromDate,
  toDate: state.settings.toDate,
});

const mapDispatchToProps = dispatch => ({
  setFromDate: fromDate => dispatch(setFromDate(fromDate)),
  setToDate: toDate => dispatch(setToDate(toDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRedirect(Dashboard));
