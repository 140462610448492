import { SET_FROM_DATE, SET_TO_DATE } from '../types';

export const setFromDate = fromDate => ({
  type: SET_FROM_DATE,
  payload: fromDate,
});

export const setToDate = toDate => ({
  type: SET_TO_DATE,
  payload: toDate,
});
