import React from 'react';
import { Layout } from 'antd';
import { HeartOutlined } from '@ant-design/icons';
import { version } from '../../config';

const Footer = () => (
  <Layout.Footer className="bg-clear-gray" style={{ textAlign: 'center' }}>
    <p className="mb-0 text-600">
      Hecho con <HeartOutlined /> amor en México <span className="d-none d-sm-inline-block">| </span>
      <br className="d-sm-none" /> {new Date().getFullYear()} <a href="mailto:amigos@orchata.com">amigos@orchata.com</a>{' '}
      | v{version}
    </p>
  </Layout.Footer>
);

export default Footer;
