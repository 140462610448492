import React, { useContext, Fragment } from 'react';
import { quantityFormatter2, getLabelRipeness, getPaymentMethodLabel } from '../../../table/Formatters';
import AppContext from 'context/Context';
import { capitalize, groupBy } from 'lodash';
import QRCode from 'qrcode.react';
import moment from 'moment';
import 'moment/locale/es';
import { momentHelper } from './../../../utils/momentTimezone';
import pickupTimes from './pickupTimes';

const TicketPrintView = ({ order, urlMaps, printComponentTicketRef, categories }) => {
  const { storefront, currency, userRoleType, locale } = useContext(AppContext);
  const localizationMoment = { timezone: storefront?.timezone, locale: locale };

  let products = order?.store_order_items.filter(p => {
    return !p.original && !p.deleted && p.amount > 0;
  });

  let groupedProducts = groupBy(products, product => product.store_category_name);

  if (storefront.id === 217 && categories) {
    let groupedProductsById = groupBy(products, product => product.store_category_id);
    let reorderedProducts = [];

    categories.forEach(element => {
      if (groupedProductsById[element.category.id]) {
        reorderedProducts[element.category.name] = groupedProductsById[element.category.id];
      }
    });

    groupedProducts = reorderedProducts;
  }

  return (
    <div
      className="hidden print"
      style={{ fontSize: 10, paddingLeft: 10, paddingRight: 10 }}
      ref={printComponentTicketRef}
    >
      <table style={{ fontSize: 12, marginBottom: 20, width: '100%' }}>
        <tbody>
          {!!storefront.logo && storefront.id !== 125 && (
            <tr>
              <td style={{ textAlign: 'center' }}>
                <img
                  src={storefront.logo.url}
                  alt="logo"
                  height="120px;"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                ></img>
              </td>
            </tr>
          )}
          <tr>
            <td style={{ verticalAlign: 'center', textAlign: 'center', fontSize: '15px' }}>
              {storefront.Name} por Orchata
            </td>
          </tr>
          {order?.store && (
            <tr>
              <td style={{ verticalAlign: 'center', textAlign: 'center', fontSize: '15px' }}>{order?.store.name}</td>
            </tr>
          )}
        </tbody>
      </table>
      <table style={{ fontSize: 12, marginBottom: 20, width: '100%' }}>
        <tbody>
          <tr>
            <td>Folio:</td>
            <td>{order?.no_order}</td>
          </tr>
          <tr>
            <td>Fecha:</td>
            <td>{moment(order?.created_at).locale('es').format('DD/MMMM/YYYY, h:mm a')}</td>
          </tr>
          {userRoleType !== 'storeoperator' && (
            <>
              <tr>
                <td>Nombre:</td>
                <td>{order?.name}</td>
              </tr>
              <tr>
                <td>Whatsapp:</td>
                <td>{order?.whatsapp}</td>
              </tr>
              <tr>
                <td>Tipo de entrega:</td>
                <td>{order?.delivery_type === 'pickup' ? 'En tienda' : order?.shippingAddress}</td>
              </tr>
            </>
          )}
          {order?.shipping_extra && (
            <tr>
              <td>Notas de envío:</td>
              <td>{order?.shipping_extra}</td>
            </tr>
          )}

          {order?.delivery_type === 'pickup'
            ? order?.pickup_date_time && (
                <tr>
                  <td>Fecha a recoger:</td>
                  <td>{`${momentHelper(localizationMoment, order.pickup_date_time).format('dddd DD [de] MMMM')} de ${
                    pickupTimes[parseInt(momentHelper(localizationMoment, order.pickup_date_time).format('H'))]
                  }`}</td>
                </tr>
              )
            : order?.delivery_day &&
              order?.delivery_time && (
                <>
                  <tr>
                    <td>Dirección de entrega:</td>
                    <td>{order?.shippingAddress}</td>
                  </tr>
                  <tr>
                    <td>Horario de entrega:</td>
                    <td>{`${moment(order?.delivery_day).locale('es').format('dddd DD [de] MMMM')} entre ${
                      order?.delivery_time
                    }`}</td>
                  </tr>
                </>
              )}
          <tr>
            <td>Método de pago:</td>
            <td>{getPaymentMethodLabel(order?.payment_method)}</td>
          </tr>
          {order?.cash_payment_with && (
            <tr>
              <td>Pagará con;</td>
              <td>
                {currency}
                {order?.cash_payment_with.toFixed(2)}
              </td>
            </tr>
          )}
          <tr>
            <td>Cantidad de artículos:</td>
            <td>{products?.length}</td>
          </tr>
          {order?.extra_notes && (
            <tr>
              <td>
                <b>Notas del pedido:</b>
              </td>
              <td>
                <b>{order?.extra_notes}</b>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table style={{ fontSize: 12, marginBottom: 20, width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ textAlign: 'left' }}>Unidad</th>
            <th style={{ textAlign: 'left' }}>Producto</th>
            <th style={{ textAlign: 'right' }}>Monto</th>
          </tr>
          {Object.keys(groupedProducts).map((category, i) => (
            <Fragment key={i}>
              <tr>
                <td></td>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <b>{!!category && category !== 'null' && category}</b>
                </td>
              </tr>
              {groupedProducts[category].map((product, i) => (
                <tr key={i}>
                  <td>
                    <b>{quantityFormatter2(product.quantity, product)}</b>
                  </td>
                  <td>
                    <b>
                      {capitalize(product.name)} {getLabelRipeness(product.ripeness)}
                    </b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <b>
                      {currency}
                      {parseFloat(product.amount).toFixed(2)}
                    </b>
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
          <tr>
            <td></td>
            <td style={{ textAlign: 'right' }}>Subtotal</td>
            <td style={{ textAlign: 'right' }}>
              {currency}
              {parseFloat(order?.subtotal).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td></td>
            {order?.delivery_type !== 'pickup' && <td style={{ textAlign: 'right' }}>Costo de envío</td>}
            <td style={{ textAlign: 'right' }}>
              {currency}
              {order?.shipping_cost
                ? order?.shipping_cost.toFixed(2)
                : order?.business.shippingCostType === 'dynamic' || order?.business.shippingCostType === 'area'
                ? (order?.total + order?.discount - order?.subtotal).toFixed(2)
                : order?.business.shippingCost.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={{ textAlign: 'right' }}>Descuento</td>
            <td style={{ textAlign: 'right' }}>
              {currency}
              {parseFloat(order?.discount || 0).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {currency}
              {parseFloat(order?.total).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
      {urlMaps && (
        <center>
          <h4>Escanea el código para ver la dirección en el mapa:</h4>
          <QRCode height={60} value={urlMaps} />
        </center>
      )}
      <br />
      <div style={{ textAlign: 'center' }}>Powered by Orchata www.orchata.com</div>
      <div style={{ textAlign: 'center', marginTop: 150, color: '#fefefe' }}>####</div>
    </div>
  );
};

export default TicketPrintView;
