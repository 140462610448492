import { Image } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Modal, Typography, Button } from '../../../ui';
import Logo from '../../../assets/img/logos/orchata_vertical_with_title.svg';

const { Text, Paragraph } = Typography;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  height: 0px;
  width: 100%;
  margin-top: 32px; // TODO: move this to theme
`;

const ActionButton = styled(Button)`
  font-size: 20px !important; // TODO: move this to theme
  height: auto !important;
  width: 300px !important;
  padding: 12px !important; // TODO: move this to theme
`;

const Background = styled.div`
  background: #fff5fa;
  padding: 48px; // TODO: Move this to theme
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 11px 11px 0px 0px;
`;

const NotificationHeader = () => {
  return (
    <Background>
      <Image preview={false} src={Logo} />
    </Background>
  );
};

const NewOrderNotification = ({ isVisible, onClose, onStart, order }) => {
  return order ? (
    <Modal width={700} visible={isVisible} onCancel={onClose} footer={null} header={<NotificationHeader />}>
      <NotificationContent>
        <Paragraph style={{ fontSize: 22 }}>
          ¡Tienes un nuevo pedido!
        </Paragraph>
        <Text type="secondary" style={{ fontSize: 17 }}>
          Pedido de
        </Text>
        <Text strong style={{ fontSize: 20 }}>
          {order.name}
        </Text>
        <Divider />
        <ActionButton onClick={onStart} type="primary" color="secondary" size="large" shape="round">
          Aceptar y preparar
        </ActionButton>
      </NotificationContent>
    </Modal>
  ) : null;
};

export default NewOrderNotification;
