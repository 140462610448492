import React from 'react';
import styled, { css } from 'styled-components';
import { Modal as AntModal } from 'antd';
import Button from '../button/Button';

const CustomModal = styled(AntModal)`
  .ant-modal-content {
    border-radius: ${props => props.theme.miscellany.borderRadius};
  }
  .ant-modal-body {
    padding: 0px;
  }
`;

const ContentWrapper = styled.div`
  min-height: 200px;
  padding: 24px; // TODO: Move this to theme
`;

const HeaderWrapper = styled.div`
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px 24px; // TODO: Move this to theme
  ${props =>
    props.size === 'large' &&
    css`
      ${Button} {
        height: 48px !important; // TODO: Move this to theme
        width: 50%;
      }
    `}
`;

const ModalHeader = ({ children }) => {
  return <HeaderWrapper>{children}</HeaderWrapper>;
};

const ModalFooter = ({ okText, cancelText, onCancel, onOk, size, cancelButtonProps }) => {
  return (
    <FooterWrapper size={size}>
      <Button
        type="primary"
        color="gray"
        shape="round"
        onClick={cancelButtonProps && cancelButtonProps.onClick ? cancelButtonProps.onClick : onCancel}
      >
        {cancelText || 'Cancelar'}
      </Button>
      &nbsp;
      <Button type="primary" color="secondary" shape="round" onClick={onOk}>
        {okText || 'Aceptar'}
      </Button>
    </FooterWrapper>
  );
};

const Modal = ({
  children,
  okText,
  cancelText,
  onCancel,
  onOk,
  footer,
  header,
  footerSize = 'default',
  cancelButtonProps,
  closable = false,
  ...rest
}) => {
  return (
    <CustomModal {...rest} title={null} footer={null} centered closable={closable} onCancel={onCancel}>
      {header && <ModalHeader>{header}</ModalHeader>}
      <ContentWrapper>{children}</ContentWrapper>
      {footer !== null && (
        <ModalFooter
          size={footerSize}
          okText={okText}
          cancelText={cancelText}
          onCancel={onCancel}
          onOk={onOk}
          cancelButtonProps={cancelButtonProps}
        />
      )}
    </CustomModal>
  );
};

export default Modal;
