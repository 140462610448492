import React from 'react';
import shortid from 'shortid';

import {
  AppstoreOutlined,
  CommentOutlined,
  CreditCardOutlined,
  MobileOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  GatewayOutlined,
  UserOutlined,
  FileTextOutlined,
  PhoneOutlined,
  ReconciliationOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

export const homeRoutes = {
  id: shortid.generate(),
  name: 'Dashboard',
  to: '/',
  roles: ['orchatateam', 'businessowner', 'storemanager', 'storeoperator', 'operationsmanager'],
  icon: <PieChartOutlined />,
};

export const storeOrdersRoutes = {
  id: shortid.generate(),
  name: 'Pedidos',
  to: '/orders',
  roles: ['orchatateam', 'businessowner', 'storemanager', 'storeoperator', 'operationsmanager'],
  icon: <ShoppingCartOutlined />,
};

export const storeOrdersPanelRoutes = {
  id: shortid.generate(),
  name: 'Centro de pedidos',
  to: '/panel/orders',
  roles: ['orchatateam', 'businessowner', 'storemanager', 'storeoperator'],
  icon: <ShoppingCartOutlined />,
};

export const storeItemsRoutes = {
  id: shortid.generate(),
  name: 'Productos',
  to: '/products',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  icon: <MobileOutlined />,
};

export const slotsRoutes = {
  id: shortid.generate(),
  name: 'Horarios',
  to: '/slots',
  roles: ['orchatateam', 'businessowner'],
  businessConditions: ['allow_scheduled_delivery'],
  icon: <FieldTimeOutlined />,
};

export const deliveriesRoutes = {
  id: shortid.generate(),
  name: 'Rutas de entrega',
  to: '/deliveries',
  roles: ['orchatateam', 'businessowner'],
  businessConditions: ['allow_routes'],
  icon: <GatewayOutlined />,
};

export const purchasesRoutes = {
  id: shortid.generate(),
  name: 'Compras',
  to: '/purchases',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  icon: <CreditCardOutlined />,
};

export const categoriesRoutes = {
  id: shortid.generate(),
  name: 'Categorías',
  to: '/categories',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  onlyForSaas: true,
  icon: <UnorderedListOutlined />,
};

export const storeCustomersRoutes = {
  id: shortid.generate(),
  name: 'Clientes',
  to: '/customers',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  icon: <UserOutlined />,
  key: 'isModuleSms',
};

export const recommendedProductsRoutes = {
  id: shortid.generate(),
  name: 'Recomendaciones',
  to: '/products_recommendations',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  icon: <CommentOutlined />,
};

export const phoneOrdersRoutes = {
  id: shortid.generate(),
  name: 'Pedidos por teléfono',
  to: '/phone-orders',
  roles: ['orchatateam', 'businessowner', 'storemanager', 'storeoperator', 'operationsmanager'],
  icon: <PhoneOutlined />,
};

export const storesRoutes = {
  id: shortid.generate(),
  name: 'Tiendas',
  to: '/stores',
  roles: ['orchatateam'],
  icon: <AppstoreOutlined />,
};

export const maintenanceRoute = {
  id: shortid.generate(),
  name: 'Mantenimiento',
  to: '/maintenance',
  review: true,
  icon: <PieChartOutlined />,
};

export const notesRoutes = {
  id: shortid.generate(),
  name: 'Notas Extra',
  to: '/extra-notes',
  roles: ['orchatateam'],
  icon: <FileTextOutlined />,
};

export const ordersScoresRoutes = {
  id: shortid.generate(),
  name: 'Evaluaciones',
  to: '/orders-scores',
  roles: ['orchatateam', 'businessowner', 'operationsmanager'],
  icon: <ReconciliationOutlined />,
};

export const customersRoutes = {
  id: shortid.generate(),
  name: 'Clientes',
  to: '/customers',
  roles: ['orchatateam'],
  icon: <UserOutlined />,
};

export const branchOfficesRoutes = {
  id: shortid.generate(),
  name: 'Sucursales',
  to: '/branch-offices',
  roles: ['orchatateam'],
  icon: <HomeOutlined />,
};

export const usersRoutes = {
  id: shortid.generate(),
  name: 'Usuarios',
  to: '/users',
  roles: ['orchatateam', 'businessowner'],
  icon: <UsergroupAddOutlined />,
};

const routes = [
  homeRoutes,
  usersRoutes,
  storeOrdersRoutes,
  storeOrdersPanelRoutes,
  storeItemsRoutes,
  purchasesRoutes,
  categoriesRoutes,
  recommendedProductsRoutes,
  storesRoutes,
  notesRoutes,
  slotsRoutes,
  deliveriesRoutes,
  phoneOrdersRoutes,
  ordersScoresRoutes,
  customersRoutes,
  branchOfficesRoutes,
];

if (process.env.REACT_APP_STAGE !== 'production') {
  routes.push(maintenanceRoute);
}

export default routes;
