import { toast } from 'react-toastify';
import {
  SHOW_STORE_ITEM_MODAL,
  ADD_STORE_ITEM,
  ADD_STORE_ITEM_SUCCESS,
  ADD_STORE_ITEM_ERROR,
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_ERROR,
  DELETE_STORE_ITEM,
  DELETE_STORE_ITEM_SUCCESS,
  DELETE_STORE_ITEM_ERROR,
  EDIT_STORE_ITEM,
  UPDATE_STORE_ITEM,
  UPDATE_STORE_ITEM_SUCCESS,
  UPDATE_STORE_ITEM_ERROR,
  CANCEL_STORE_ITEM_MODAL,
  FILTER_STORE_ITEMS,
} from '../types';
import clientAxios from '../config/axios';
import { getItemFromStore } from '../helpers/utils';

// === SHOW STORE ITEM MODAL ===
export const showStoreItemModal = show => {
  return dispatch => {
    dispatch(showModal(show));
  };
};

const showModal = show => ({
  type: SHOW_STORE_ITEM_MODAL,
  payload: show,
});

// === CANCEL ITEM MODAL ===
export const cancelStoreItemModal = hide => {
  return dispatch => {
    dispatch(hideModal(hide));
  };
};

const hideModal = hide => ({
  type: CANCEL_STORE_ITEM_MODAL,
  payload: hide,
});

// === CREATE NEW STORE ITEM ===
export const createNewStoreItem = storeItem => {
  return async dispatch => {
    dispatch(addStoreItem());
    const storefront = getItemFromStore('o_s', {});
    const path = storefront.isSaas ? '/store-items' : '/products';
    await clientAxios
      .post(path, storeItem, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      })
      .then(result => {
        if (result.data) {
          dispatch(addStoreItemSuccess(result.data));
          toast('Producto agregado correctamente');
        }
      })
      .catch(err => {
        console.log(err);
        console.log('err, creating a new store storeItem');
        dispatch(addStoreItemError(true));
      });
  };
};

const addStoreItem = () => ({
  type: ADD_STORE_ITEM,
  payload: true,
});

const addStoreItemSuccess = storeItem => ({
  type: ADD_STORE_ITEM_SUCCESS,
  payload: storeItem,
});

const addStoreItemError = state => ({
  type: ADD_STORE_ITEM_ERROR,
  payload: state,
});

// === GET STORE ITEMS ===
/**
 * Get store items from endpoint using page, sizePerPage and sort
 * @param {Integer} page
 * @param {Integer} sizePerPage
 * @param {String} sort
 */
export const getStoreItemsAction = ({ page, sizePerPage, sort, qs, category, currentStore }) => {
  return async dispatch => {
    try {
      dispatch(getStoreItems(page, sizePerPage, sort));
      const storefront = getItemFromStore('o_s', {});

      let path = storefront.isSaas ? '/store-items' : '/products';

      let options = {};
      if (storefront.isSaas) options['business'] = storefront.id;
      if (sort === null) sort = 'name:asc';

      if (qs && qs !== '') {
        options['name_contains'] = qs;
      }
      if (category) options['category.id'] = category.id;
      if (currentStore && (storefront.is_store_selectable || storefront.is_store_selected_delayed))
        options['store'] = currentStore.id;

      let countPath = path + `/count`;
      if (Object.keys(options).length > 0) countPath += `?${new URLSearchParams(options).toString()}`;

      const count = await clientAxios.get(countPath, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      });
      const totalSize = count.data;
      const start = (page - 1) * sizePerPage;
      const limit = sizePerPage;

      options['_sort'] = sort;
      options['_start'] = start;
      options['_limit'] = limit;

      path += `?${new URLSearchParams(options).toString()}`;

      const result = await clientAxios.get(path, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      });

      if (result.data) dispatch(getStoreItemsSuccess(result.data, totalSize));
    } catch (err) {
      console.error(err);
      console.error('err, creating a new store ORDER');
      dispatch(getStoreItemsError(true));
    }
  };
};

const getStoreItems = (page, sizePerPage, sort) => ({
  type: GET_STORE_ITEMS,
  payload: { page, sizePerPage, sort },
});

const getStoreItemsSuccess = (storeItems, totalSize) => ({
  type: GET_STORE_ITEMS_SUCCESS,
  payload: storeItems,
  totalSize: totalSize,
});

const getStoreItemsError = state => ({
  type: GET_STORE_ITEMS_ERROR,
  payload: state,
});

// === DELETE STORE ITEM ===
export const deleteStoreItemAction = id => {
  return async dispatch => {
    if (getItemFromStore('o_s', false)) {
      const storefront = getItemFromStore('o_s', {});
      const path = storefront.isSaas ? `/store-items/${id}?business=${storefront.id}` : `/products/${id}`;
      dispatch(deleteStoreItem(id));
      await clientAxios
        .delete(path, {
          headers: {
            Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
          },
        })
        .then(() => {
          dispatch(deleteStoreItemSuccess());
          toast('Producto eliminado correctamente');
        })
        .catch(err => {
          dispatch(deleteStoreItemError());
          console.log(err);
        });
    } else {
      console.log('sorry, wait for it...');
    }
  };
};

const deleteStoreItem = id => ({
  type: DELETE_STORE_ITEM,
  payload: id,
});

const deleteStoreItemSuccess = () => ({
  type: DELETE_STORE_ITEM_SUCCESS,
});

const deleteStoreItemError = () => ({
  type: DELETE_STORE_ITEM_ERROR,
  payload: true,
});

// === EDIT  STORE ITEM ===
export const editStoreItemAction = storeItem => {
  return dispatch => {
    dispatch(editStoreItem(storeItem));
  };
};

const editStoreItem = storeItem => ({
  type: EDIT_STORE_ITEM,
  payload: storeItem,
});

// === UPDATE STORE ITEM ===
export const updateStoreItemAction = storeItem => {
  return async dispatch => {
    if (getItemFromStore('o_s', false)) {
      const storefront = getItemFromStore('o_s', {});
      const path = storefront.isSaas
        ? `/store-items/${storeItem.id}?business=${storeItem.business.id}`
        : `/products/${storeItem.id}`;
      dispatch(updateStoreItem(storeItem));
      await clientAxios
        .put(path, storeItem, {
          headers: {
            Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
          },
        })
        .then(result => {
          if (result.data) {
            dispatch(updateStoreItemSuccess(result.data));
            toast('Producto actualizado correctamente');
          }
        })
        .catch(err => {
          dispatch(updateStoreItemError());
          console.log(err);
        });
    } else {
      console.log('sorry, wait for it...');
    }
  };
};

const updateStoreItem = storeItem => ({
  type: UPDATE_STORE_ITEM,
  payload: storeItem,
});

export const updateStoreItemSuccess = storeItem => ({
  type: UPDATE_STORE_ITEM_SUCCESS,
  payload: storeItem,
});

const updateStoreItemError = () => ({
  type: UPDATE_STORE_ITEM_ERROR,
  payload: true,
});

export const filterStoreItemsAction = ({ category, qs }) => {
  return async dispatch => {
    if (getItemFromStore('o_s', false)) {
      dispatch(filterStoreItems({ category, qs }));
    } else {
      console.log('sorry, wait for it...');
    }
  };
};

const filterStoreItems = args => {
  return {
    type: FILTER_STORE_ITEMS,
    payload: args,
  };
};
