import clientAxios from '../../config/axios';

export const getStoreOrdersQuery = async (business_id, token, fromDate, toDate) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}&status_ne=canceled&_sort=created_at:desc&_limit=999`;
      if (fromDate) query += `&created_at_gte=${fromDate.startOf('day').format()}`;
      if (toDate) query += `&created_at_lte=${toDate.endOf('day').format()}`;
      const result = await clientAxios.get(`/store-orders${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getStoreOrdersCount = async (business_id, token, fromDate, toDate, store, currentCity) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}&status_ne=canceled&_sort=created_at:desc`;
      if (fromDate) query += `&created_at_gte=${fromDate.startOf('day').format()}`;
      if (toDate) query += `&created_at_lte=${toDate.endOf('day').format()}`;
      if (store & !currentCity) query += `&store=${store.id}`;
      if (currentCity) query += `&store_in=${currentCity.join('&store_in=')}`;
      const result = await clientAxios.get(`/store-orders/count${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getStoreRevenue = async (business_id, token, fromDate, toDate, store, currentCity) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}&_sort=created_at:desc&_limit=999`;
      if (fromDate) query += `&created_at_gte=${fromDate.startOf('day').format()}`;
      if (toDate) query += `&created_at_lte=${toDate.endOf('day').format()}`;
      if (store & !currentCity) query += `&store=${store.id}`;
      if (currentCity) query += `&store_in=${currentCity.toString()}`;
      const result = await clientAxios.get(`/store-orders/total${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getStoreCustomers = async (business_id, token, fromDate, toDate, store, currentCity) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}&_sort=created_at:desc&_limit=999`;
      if (fromDate) query += `&created_at_gte=${fromDate.startOf('day').format()}`;
      if (toDate) query += `&created_at_lte=${toDate.endOf('day').format()}`;
      if (store & !currentCity) query += `&store=${store.id}`;
      if (currentCity) query += `&store_in=${currentCity.toString()}`;
      const result = await clientAxios.get(`/store-orders/clients${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getKpis = async (business_id, token, fromDate, toDate, store, currentCity) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}&_sort=created_at:desc&_limit=999`;
      if (fromDate) query += `&created_at_gte=${fromDate.startOf('day').format()}`;
      if (toDate) query += `&created_at_lte=${toDate.endOf('day').format()}`;
      if (store & !currentCity) query += `&store=${store.id}`;
      if (currentCity) query += `&store_in=${currentCity.toString()}`;
      const result = await clientAxios.get(`/store-orders/kpis${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getTotals = async token => {
  try {
    // ?&created_at_lte=2020-07-01&created_at_gte=2020-07-30
    const result = await clientAxios.get(`/manager/total`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getDashboard = async (business, token) => {
  try {
    // ?&created_at_lte=2020-07-01&created_at_gte=2020-07-30
    const path = business ? `/manager/info?business=${business}` : '/manager/dashboard';
    const result = await clientAxios.get(path, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};
