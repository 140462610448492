import { Input as AntInput } from 'antd';
import styled from 'styled-components';
import { percentToHex } from 'theme';

const Input = styled(AntInput)`
  border-radius: ${props => props.theme.miscellany.borderRadius} !important;
  border: 1px solid ${props => props.theme.colors.gray} !important;
  background-color: ${props => props.theme.colors.white} !important;
  padding: 5px 10px !important;
  min-height: 44px !important;
  font-family: ${props => props.theme.typography.family};
  line-height: 2.2 !important;
  .ant-input-prefix {
    transition: all 0.3s !important;
  }
  &.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
  &:hover {
    border-color: ${props => props.theme.colors.blue} !important;
    outline: 0 !important;
    box-shadow: 0 0 0 2px ${props => props.theme.colors.blue + percentToHex(20)} !important;
    .ant-input-prefix {
      color: ${props => props.theme.colors.blue} !important;
    }
  }
  &:hover {
    box-shadow: none !important;
  }
`;

export default Input;
