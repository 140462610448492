import { percentToHex } from './helpers';

const colors = {
  blue: '#3588FF',
  yellow: '#FF9900',
  orange: '#F15A29',
  pink: '#FF339C',
  purple: '#822FEB',
  green: '#70BF42',
  white: '#FFFFFF',
  red: '#D22F26',
  gray: '#E0E0E0',
  transparent: 'rgba(0,0,0,0)',
  overlay: 'rgba(0,0,0,0.08)',
  // SEMANTIC COLORS
  primary: '#FF339C',
  secondary: '#363F4E',
  danger: '#D22F26',
  success: '#70BF42',
  disabled: `#E0E0E0${percentToHex(30)}`,
  background: '#EEF1F4',
  text: '#363F4E',
  link: `#3588FF${percentToHex(70)}`,
};

export default colors;
