// SETTINGS REDUCER
export const SET_TO_DATE = 'SET_TO_DATE';
export const SET_FROM_DATE = 'SET_FROM_DATE';

// STORE CATEGORIES
export const SHOW_CATEGORY_MODAL = 'SHOW_CATEGORY_MODAL';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
export const CANCEL_CATEGORY_MODAL = 'CANCEL_CATEGORY_MODAL';

// STORE ITEMS
export const SHOW_STORE_ITEM_MODAL = 'SHOW_STORE_ITEM_MODAL';
export const ADD_STORE_ITEM = 'ADD_STORE_ITEM';
export const ADD_STORE_ITEM_SUCCESS = 'ADD_STORE_ITEM_SUCCESS';
export const ADD_STORE_ITEM_ERROR = 'ADD_STORE_ITEM_ERROR';
export const GET_STORE_ITEMS = 'GET_STORE_ITEMS';
export const GET_STORE_ITEMS_SUCCESS = 'GET_STORE_ITEMS_SUCCESS';
export const GET_STORE_ITEMS_ERROR = 'GET_STORE_ITEMS_ERROR';
export const DELETE_STORE_ITEM = 'DELETE_STORE_ITEM';
export const DELETE_STORE_ITEM_SUCCESS = 'DELETE_STORE_ITEM_SUCCESS';
export const DELETE_STORE_ITEM_ERROR = 'DELETE_STORE_ITEM_ERROR';
export const EDIT_STORE_ITEM = 'EDIT_STORE_ITEM';
export const UPDATE_STORE_ITEM = 'UPDATE_STORE_ITEM';
export const UPDATE_STORE_ITEM_SUCCESS = 'UPDATE_STORE_ITEM_SUCCESS';
export const UPDATE_STORE_ITEM_ERROR = 'UPDATE_STORE_ITEM_ERROR';
export const CANCEL_STORE_ITEM_MODAL = 'CANCEL_STORE_ITEM_MODAL';
export const FILTER_STORE_ITEMS = 'FILTER_STORE_ITEMS';

// STORE ORDERS
export const SHOW_STORE_ORDER_MODAL = 'SHOW_STORE_ORDER_MODAL';
export const ADD_STORE_ORDER = 'ADD_STORE_ORDER';
export const ADD_STORE_ORDER_SUCCESS = 'ADD_STORE_ORDER_SUCCESS';
export const ADD_STORE_ORDER_ERROR = 'ADD_STORE_ORDER_ERROR';
export const GET_STORE_ORDERS = 'GET_STORE_ORDERS';
export const GET_STORE_ORDERS_SUCCESS = 'GET_STORE_ORDERS_SUCCESS';
export const GET_STORE_ORDERS_ERROR = 'GET_STORE_ORDERS_ERROR';
export const DELETE_STORE_ORDER = 'DELETE_STORE_ORDER';
export const DELETE_STORE_ORDER_SUCCESS = 'DELETE_STORE_ORDER_SUCCESS';
export const DELETE_STORE_ORDER_ERROR = 'DELETE_STORE_ORDER_ERROR';
export const EDIT_STORE_ORDER = 'EDIT_STORE_ORDER';
export const CANCEL_STORE_ORDER_MODAL = 'CANCEL_STORE_ORDER_MODAL';
export const FILTER_STORE_ORDERS = 'FILTER_STORE_ORDERS';

// SMS TO USERS
export const SEND_SMS_SHOW_MODAL = 'SEND_SMS_SHOW_MODAL';
export const SEND_SMS_CANCEL_MODAL = 'SEND_SMS_CANCEL_MODAL';
export const SEND_SMS_TO_USERS = 'SEND_SMS_TO_USERS';
export const SEND_SMS_TO_USERS_SUCCESS = 'SEND_SMS_TO_USERS_SUCCESS';
export const SEND_SMS_TO_USERS_ERROR = 'SEND_SMS_TO_USERS_ERROR';

// PRODUCT RECOMMENDATIOONS
export const GET_PRODUCTS_RECOMMENDATIONS = 'GET_PRODUCTS_RECOMMENDATIONS';
export const GET_PRODUCTS_RECOMMENDATIONS_SUCCESS = 'GET_PRODUCTS_RECOMMENDATIONS_SUCCESS';
export const GET_PRODUCTS_RECOMMENDATIONS_ERROR = 'GET_PRODUCTS_RECOMMENDATIONS_ERROR';
export const MARK_AS_READ_PRODUCT_RECOMMENDATION = 'MARK_AS_READ_PRODUCTS_RECOMMENDATION';
export const MARK_AS_READ_PRODUCT_RECOMMENDATION_SUCCESS = 'MARK_AS_READ_PRODUCTS_RECOMMENDATION_SUCCESS';
export const MARK_AS_READ_PRODUCT_RECOMMENDATION_ERROR = 'MARK_AS_READ_PRODUCTS_RECOMMENDATION_ERROR';
export const FILTER_PRODUCTS_RECOMMENDATIONS = 'FILTER_PRODUCTS_RECOMMENDATIONS';
export const TOOGLE_READED_PRODUCTS_RECOMMENDATIONS = 'TOOGLE_READED_PRODUCTS_RECOMMENDATIONS';
