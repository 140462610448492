import useAntBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useMemo } from 'react';

export default function useBreakpoint() {
  const breakpoint = useAntBreakpoint();
  const currentBreakpoint = useMemo(() => {
    if (breakpoint.xxl) return 'xxl';
    if (breakpoint.xl) return 'xl';
    if (breakpoint.lg) return 'lg';
    if (breakpoint.md) return 'md';
    if (breakpoint.sm) return 'sm';
    return 'xs';
  }, [breakpoint]);
  return currentBreakpoint;
}
