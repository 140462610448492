import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import AppContext from './context/Context';
import { getItemFromStore, getStorefrontUrl, setItemToStore } from './helpers/utils';
import { getBusiness } from './queries/strapi/storefront';

const Main = props => {
  const initialState = {};
  const [currency, setCurrency] = useState('$');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [storefront, setStorefront] = useState(initialState);
  const [storefrontUrl, setStorefrontUrl] = useState();
  const [currentStore, setCurrentStore] = useState(null);
  const [currentCity, setCurrentCity] = useState(null);
  const [loginCount, setLoginCount] = useState(0);
  const [stores, setStores] = useState(null);
  const [userRoleType, setUserRoleType] = useState('');
  const storeOrdersRefetchRef = useRef();
  const storeItemsRefetchRef = useRef();
  const storeOrdersRoutesRefetchRef = useRef();
  //TODO: Determine if locale will come from browser or store configuration
  const [locale, setLocale] = useState('es');

  const value = {
    currency,
    setCurrency,
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken,
    storefront,
    setStorefront,
    stores,
    setStores,
    currentStore,
    setCurrentStore,
    currentCity, 
    setCurrentCity,
    userRoleType,
    setUserRoleType,
    storefrontUrl,
    storeOrdersRefetchRef,
    storeItemsRefetchRef,
    storeOrdersRoutesRefetchRef,
    loginCount,
    setLoginCount,
    locale,
    setLocale,
  };

  useEffect(() => {
    if (getItemFromStore('o_s', false)) {
      setStorefront(getItemFromStore('o_s', {}));
    }
    const refreshStore = async (id, token) => {
      const s = await getBusiness({ id }, token);
      if (s) {
        setItemToStore('o_s', s);
        setStorefront(s);
        if (s.stores.length > 0) {
          setStores(s.stores);
          if (
            getItemFromStore('urt', '') === 'storemanager' ||
            getItemFromStore('urt', '') === 'storeoperator' ||
            getItemFromStore('urt', '') === 'operationsmanager'
          ) {
            setCurrentStore(getItemFromStore('us', null));
          } else {
            setCurrentStore(null);
          }
        } else {
          setStores(null);
        }
      }
    };
    try {
      const localstore = getItemFromStore('o_s', false);
      if (localstore) {
        refreshStore(localstore.id, getItemFromStore('jwt_user_token', ''));
      }
    } finally {
      setIsLoaded(true);
    }
  }, [userRoleType, loginCount]);

  // Set storefront (frontend) url
  useEffect(() => {
    setStorefrontUrl(getStorefrontUrl(storefront.domain));
  }, [storefront.domain]);

  useEffect(() => {
    setIsLoggedIn(getItemFromStore('isLoggedIn', false));
    setToken(getItemFromStore('jwt_user_token', ''));
    setUserRoleType(getItemFromStore('urt', ''));
  }, []);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
