import React from 'react';
import { RightOutlined, PrinterOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import { priceFormatter, statusFormatter } from '../../table/Formatters';
import { Button, Typography } from '../../../ui';
import { useAppContext } from 'context/Context';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

const { Text, Paragraph } = Typography;

const updateStoreOrderQuery = loader('queries/graphql/storeOrders/updateStoreOrder.graphql');

const PrinterButton = styled(Button)`
  box-shadow: ${props => props.theme.miscellany.boxShadow} !important;
`;

const ActionButton = styled(Button)`
  height: 34px !important;
`;

// === Helpers ===
const getNextStatus = (currentStatus, deliveryType) => {
  switch (currentStatus) {
    case 'new':
      return { status: 'processing', text: 'Preparar' };
    case 'processing':
      return { status: 'ready', text: 'Pedido listo' };
    case 'ready':
      return deliveryType === 'shipping'
        ? { status: 'pending', text: 'En camino' }
        : { status: 'completed', text: 'Entregado' };
    case 'pending':
      return { status: 'delivered', text: 'Entregado' };
    default:
      return { status: 'processing', text: 'Preparar' };
  }
};

// === FORMATTERS ===
const clientNameFormatter = clientName => {
  return <Text className="capitalize my-auto">{clientName}</Text>;
};

const paymentFormatter = paymentMethod => {
  return paymentMethod === 'cash' ? 'Efectivo' : 'Tarjeta';
};

const NextStepRenderer = ({
  id,
  order,
  handlePrint,
  handleOrderTotal,
  pickers,
  setHasPickers,
  changeStatus,
  setCurrentOrder,
}) => {
  const nextStatus = getNextStatus(order.status, order.delivery_type);
  const { storefront } = useAppContext();
  const [updateStoreOrder] = useMutation(updateStoreOrderQuery);
  const changeStatusStoreOrder = async (id, status) => {
    await updateStoreOrder({
      variables: {
        id,
        storeOrder: {
          status,
        },
      },
    });
  };

  const handleClick = () => {
    if (nextStatus.status === 'processing') {
      if (pickers?.length > 0) {
        setCurrentOrder(order);
        setHasPickers(true);
      }
    }

    if (
      nextStatus.status === 'processing' &&
      (!!order?.business?.print_device?.id || !!order?.store?.print_device?.id)
    ) {
      handlePrint(order);
    }

    if (storefront?.has_editable_store_orders && nextStatus.status === 'ready') {
      handleOrderTotal(order);
    } else {
      // Fake status change to have user feedback sooner.
      changeStatus(id, nextStatus.status);
      changeStatusStoreOrder(id, nextStatus.status);
    }
  };

  return (
    <>
      {!['delivered', 'completed', 'canceled', 'scheduled'].includes(order.status) && (
        <ActionButton type="primary" color="secondary" shape="round" onClick={handleClick}>
          {nextStatus.text} <RightOutlined />
        </ActionButton>
      )}
    </>
  );
};

const PrintRenderer = (order, handlePrint) => {
  const handleClick = () => {
    handlePrint(order);
  };
  return <PrinterButton onClick={handleClick} shape="round" icon={<PrinterOutlined />} />;
};

// === COLUMNS ===
export const columns = ({
  handlePrint,
  handleOrderTotal,
  userRoleType,
  pickers,
  setHasPickers,
  changeStatus,
  setCurrentOrder,
}) =>
  [
    {
      title: 'ID',
      dataIndex: 'no_order',
      responsive: ['md'],
      width: 100,
      className: 'px-2',
      render(no_order) {
        return <Text>{no_order}</Text>;
      },
    },
    userRoleType !== 'storeoperator'
      ? {
          title: 'Nombre',
          dataIndex: 'name',
          render: clientNameFormatter,
          width: 120,
        }
      : {},
    {
      title: 'Pago',
      dataIndex: 'payment_method',
      filterMultiple: true,
      render(method) {
        return <Text>{paymentFormatter(method)}</Text>;
      },
      responsive: ['md'],
      width: 90,
    },
    {
      title: 'Hora de entrega',
      dataIndex: 'id',
      width: 160,
      render(id, order) {
        if (order.delivery_type === 'shipping' && order.delivery_day && order.delivery_time) {
          return (
            <>
              <Paragraph className="mb-0 delivery-time-text">
                {moment(order.delivery_day).format('DD [de] MMM')}
              </Paragraph>
              <Paragraph className="mb-0 delivery-time-text">{order.delivery_time}</Paragraph>
            </>
          );
        } else {
          return (
            <Text className="mb-0 delivery-time-text">
              {moment(order.pickup_date_time).format('DD [de] MMM HH:mm A')}
            </Text>
          );
        }
      },
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      filterMultiple: true,
      width: 150,
      render: statusFormatter,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: 100,
      render(total) {
        return <Text>{priceFormatter(total)}</Text>;
      },
      responsive: ['md'],
    },
    {
      title: 'Siguiente paso',
      dataIndex: 'id',
      render: (id, order) =>
        NextStepRenderer({
          id,
          order,
          handlePrint,
          handleOrderTotal,
          pickers,
          setHasPickers,
          changeStatus,
          setCurrentOrder,
        }),
    },
    {
      title: 'Imprimir',
      dataIndex: 'id',
      width: 90,
      render: (_, order) => PrintRenderer(order, handlePrint),
    },
  ].filter(obj => Object.keys(obj).length > 0);
