import { Avatar, Col, List as AntList, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Modal, Typography } from '../../../ui';

const { Paragraph } = Typography;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  height: 0px;
  width: 100%;
  margin-top: 32px; // TODO: move this to theme
`;

const List = styled(AntList)`
  max-height: 305px !important;
  overflow-y: auto !important;
  min-width: 230px !important;
  max-width: 230px !important;
`;

const Item = styled(List.Item)`
  border-radius: ${props => props.theme.miscellany.borderRadius} !important;
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.colors.overlay} !important;
  }
`;

const colors = ['#3588FF', '#FF9900', '#822FEB', '#70BF42', '#FF339C', '#D22F26', '#F15A29'];

const AssignPickerNotification = ({ isVisible, onSelect, order, pickers }) => {
  const renderPickerItem = (picker, index) => {
    const handleClick = () => {
      onSelect(picker.id);
    };
    return (
      <Item className="px-2" onClick={handleClick}>
        <Row style={{ width: '100%' }} align="middle">
          <Col span={5}>
            <Avatar style={{ backgroundColor: colors[index % colors.length] }}>
              {picker?.name.charAt(0).toUpperCase()}
            </Avatar>
          </Col>
          <Col span={19}>
            <Paragraph style={{ width: '100%', margin: 0 }} ellipsis={{ rows: 1 }}>
              {picker?.name}
            </Paragraph>
          </Col>
        </Row>
      </Item>
    );
  };

  return (
    <Modal width={700} visible={isVisible} footer={null} header={null}>
      <NotificationContent>
        <Paragraph style={{ fontSize: 22 }}>Selecciona un picker</Paragraph>
        <List
          split={false}
          dataSource={pickers?.filter(e => e?.store?.id === parseInt(order?.store?.id) || !order?.store)}
          renderItem={renderPickerItem}
        />
        <Divider />
      </NotificationContent>
    </Modal>
  );
};

export default AssignPickerNotification;
