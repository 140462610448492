import React from 'react';
import PropTypes from 'prop-types';
import { ChartCard, Field } from './stores/Charts';
import CountUp from 'react-countup';

const CardSummary = ({ title, total, loading, hideSymbol, decimals = 0, suffix = '' }) => {
  return (
    <ChartCard
      bordered={false}
      title={title}
      loading={loading}
      total={<CountUp separator="," prefix={hideSymbol ? '' : '$'} suffix={suffix} end={total} decimals={decimals} />}
      footer={<Field label="" value={``} />}
      contentHeight={46}
    ></ChartCard>
  );
};

CardSummary.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  hideSymbol: PropTypes.bool,
};

export default CardSummary;
