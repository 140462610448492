import styled from 'styled-components';
import { Table as AntTable } from 'antd';

const Table = styled(AntTable)`
  border-radius: 11px;
  overflow: hidden;
  .ant-table-thead > tr > th {
    background: ${props => props.theme.colors.white};
  }
`;

export default Table;
