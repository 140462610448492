import sleep from './sleep';

export default async function postMessage(type, data) {
  let retryCount = 0;
  const exec = async () => {
    if (window.webkit?.messageHandlers?.cordova_iab) {
      const payload = {
        message: {
          type,
          data,
        },
      };
      const serializedPayload = JSON.stringify(payload);
      window.webkit.messageHandlers.cordova_iab.postMessage(serializedPayload);
      retryCount = 0;
    } else {
      // Only retry 10 times
      // If message is not sent, abort and warn
      if (retryCount < 10) {
        await sleep(100);
        retryCount++;
        await exec();
      }
    }
  };
  exec();
}
