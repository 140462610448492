import React, { useState } from 'react';
import { Button, Space } from 'antd';
import 'moment/locale/es';
import moment from 'moment';
import { RangePicker } from 'ui';

const DateRangePicker = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  onFocusCallback = () => {},
  onFilterCallback = () => {},
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onChangeDate = dates => {
    if (dates?.[0]) {
      setFromDate(dates[0]);
    } else {
      setFromDate(null);
    }
    if (dates?.[1]) {
      setToDate(dates[1]);
    } else {
      setToDate(null);
    }
    onFilterCallback();
  };

  const startOfDates = startOf => {
    setFromDate(moment().startOf(startOf));
    setToDate(moment().add(1,'days'));
    setIsOpen(!isOpen);
    onFilterCallback();
  };

  const lastWeek = () => {
    setFromDate(moment().subtract(1, 'weeks').startOf('week'));
    setToDate(moment().subtract(1, 'weeks').endOf('week'));
    setIsOpen(!isOpen);
    onFilterCallback();
  };

  const handleFocus = () => {
    setIsOpen(true);
    onFocusCallback();
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleCalendarChange = dates => {
    onChangeDate(dates);
    onFilterCallback();
  };

  const renderFooter = () => {
    const setDay = () => startOfDates('day');
    const setWeek = () => startOfDates('week');
    const setMonth = () => startOfDates('month');
    const setLastWeek = () => lastWeek();
    return (
      <Space size={8}>
        <Button onClick={setDay}>Hoy</Button>
        <Button onClick={setWeek}>Esta semana</Button>
        <Button onClick={setMonth}>Este mes</Button>
        <Button onClick={setLastWeek}>Semana pasada</Button>
      </Space>
    );
  };

  return (
    <RangePicker
      open={isOpen}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onCalendarChange={handleCalendarChange}
      value={[fromDate, toDate]}
      {...rest}
      renderExtraFooter={renderFooter}
    />
  );
};

export default DateRangePicker;
