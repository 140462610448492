import { loader } from 'graphql.macro';
import clientAxios from '../../config/axios';
import apolloClient from '../../utils/apolloClient';

const findBusiness = loader('../graphql/businesses/findBusiness.graphql');
const findBusinesses = loader('../graphql/businesses/findBusinesses.graphql');

export const getBusiness = async (storefront, token) => {
  try {
    const result = await apolloClient.query({
      query: findBusiness,
      variables: {
        id: storefront.id,
      },
    });

    const business = result.data?.businesses[0];

    return {
      ...business,
      id: Number(business.id),
      stores: business.stores.map(store => ({ ...store, id: Number(store.id) })),
    };
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getBusinesses = async token => {
  try {
    const result = await apolloClient.query({
      query: findBusinesses,
    });

    const businesses = result.data?.businesses.map(business => {
      return {
        ...business,
        id: Number(business.id),
        stores: business.stores.map(store => ({ ...store, id: Number(store.id) })),
      };
    });

    return businesses;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateStore = async (storefront, token) => {
  try {
    const result = await clientAxios.put(`/businesses/${storefront.id}`, storefront, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};
