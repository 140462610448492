export const percentToHex = p => {
  const intValue = Math.round((p / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

export const getButtonBackgroundColor = ({ type = 'default', theme, color, disabled }) => {
  if (type === 'text' || type === 'link' || type === 'ghost') {
    return theme.colors.transparent;
  }
  if (disabled) {
    return theme.colors.disabled;
  }
  if (type === 'danger') {
    return theme.colors.danger;
  }
  if (type === 'primary') {
    return theme.colors[color] || theme.colors.primary;
  }
  return theme.colors.white;
};

export const getButtonTextColor = ({ type = 'default', theme, color, disabled }) => {
  if (type === 'text') {
    return theme.colors.text;
  }
  if (type === 'link' || type === 'ghost') {
    return theme.colors[color] || theme.colors.primary;
  }
  if (disabled) {
    return `${theme.colors.text}${percentToHex(75)}`;
  }
  if (type === 'primary' || type === 'danger') {
    if (color === 'gray') {
      return theme.colors.text;
    }
    return theme.colors.white;
  }
  return theme.colors.text;
};

export const getButtonBorderColor = ({ type = 'default', theme, color, disabled }) => {
  if (type === 'text' || type === 'link') {
    return theme.colors.transparent;
  }
  if (disabled) {
    return theme.colors.disabled;
  }
  if (type === 'danger') {
    return theme.colors.danger;
  }
  if (type === 'primary' || type === 'ghost') {
    return theme.colors[color] || theme.colors.primary;
  }
  return theme.colors.white;
};
