import React, { useState } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import PanelRoutes from '../routes/PanelRoutes';
import { PanelNavigation } from '../components/panel';
import { Background } from '../ui';
import PanelContext from '../context/PanelContext';

const { Content } = Layout;

const Container = styled.section`
  padding: 16px; // TODO: Move this to theme.spacing
  max-height: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    padding: 24px; // TODO: Move this to theme
  }
`;

const PanelLayout = () => {
  const [title, setTitle] = useState();
  const [currentDriver, setCurrentDriver] = useState();

  return (
    <PanelContext.Provider value={{ title, setTitle, currentDriver, setCurrentDriver }}>
      <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
        <PanelNavigation title={title} />
        <Content>
          <Background height="calc(100vh - 64px)">
            <Container>
              <PanelRoutes />
            </Container>
          </Background>
        </Content>
      </Layout>
    </PanelContext.Provider>
  );
};

export default PanelLayout;
