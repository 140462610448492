import { Switch } from 'antd';
import { jsonToQueryString } from 'helpers/utils';
import { startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Button, Tag, Typography } from 'ui';
import imgerror from '../../assets/img/illustrations/supermarket.png';
/**
 * Set of formatters used in bootstrap table
 */

export const amountFormatter = amount => <span className="w-100 text-right">${amount}</span>;

const ORCHATA_STORE = 217;

export const badgeFormatter = status => {
  let color = '';
  let text = '';
  switch (status) {
    case 'completed':
    case 'delivered':
      color = 'green';
      text = 'Entregado';
      break;
    case 'new':
      color = 'blue';
      text = 'Nuevo';
      break;
    case 'processing':
      color = 'orange';
      text = 'En proceso';
      break;
    case 'pending':
      color = 'magenta';
      text = 'Pendiente';
      break;
    case 'canceled':
      color = 'red';
      text = 'Cancelado';
      break;
    default:
      color = 'orange';
      text = 'En proceso';
      break;
  }
  return <Tag color={color}>{text}</Tag>;
};

export const breakName = name =>
  !name ? 'N/A' : isMobile ? `${name.split(' ')?.[0]} ${name.split(' ')?.[1] || ''}` : name;

export const clientFormatter = name => (
  <Typography.Text className="capitalize my-auto">
    {breakName(name)}
  </Typography.Text>
);

export const viewOrderFormatter = (no_order, queryParams) => (
  <Link to={`/orders/${no_order}${jsonToQueryString(queryParams)}`}>
    <Button color="link" type="link" size="small">
      {no_order}
    </Button>
  </Link>
);

export const orderFormatter = (name, { titles }) => (
  <span className="font-weight-semi-bold btn btn-white text-left">{name || titles}</span>
);

export const quantityFormatter = (accQuantity, { measure, weight }) => (
  <>
    {parseFloat(accQuantity).toFixed(2)} {measure}
    {measure === 'pz' && weight > 0 && weight < 1
      ? ` ( aprox ${parseFloat(accQuantity * weight)?.toFixed(3)} kg )`
      : ''}
  </>
);

export const quantityFormatter2 = (accQuantity, { measure, weight }) => (
  <>
    {parseFloat(accQuantity).toFixed(2)} {measure}
    {measure === 'pz' && weight > 0 && weight < 1
      ? ` ( ${parseFloat(accQuantity * weight)?.toFixed(3)} kg )`
      : measure === 'reb' && weight > 0 && weight < 1
      ? ` ( ${parseFloat(accQuantity * weight)?.toFixed(3)} kg )`
      : ''}
  </>
);

export const imageFormatter = (image, { name }) => (
  <img className="w-auto" style={{ maxHeight: 40 }} src={image || imgerror} alt={name} />
);

export const productFormatter = (name, { titles }) => (
  <h6 className="mb-0 text-capitalize">{(name && name.toLowerCase()) || titles}</h6>
);

export const dateFormatter = date => {
  const _date = new Date(date);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
  const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(_date);
  return `${day}/${month}/${year}`;
};

export const dateTimeFormatter = date => {
  return moment(date).format('lll');
};

export const dateTimeHumanizeFormatter = date => {
  return moment(date).humanize();
};

export const dateSemiBoldFormatter = date => (
  <span className="font-weight-semi-bold btn btn-white text-left pl-0 text-capitalize">{dateFormatter(date)}</span>
);

export const shippingFormatter = (address, { business, delivery_type, shippingAddress, shipping_distance }) => (
  <>
    {delivery_type === 'pickup' ? (
      'En Tienda'
    ) : (
      <>
        <Typography.Text>{shippingAddress}</Typography.Text>
        {parseInt(business?.id) === ORCHATA_STORE && <Typography.Text> {shipping_distance}</Typography.Text>}
      </>
    )}
  </>
);

export const paymentFormatter = payment_method => {
  let text = '';
  let color = '';
  switch (payment_method) {
    case 'cash':
      text = 'Efectivo';
      color = 'green';
      break;
    case 'transfer':
      text = 'Transferencia';
      color = 'purple';
      break;
    case 'card':
      text = 'Tarjeta';
      color = 'geekblue';
      break;
    case 'terminal':
      text = 'Terminal';
      color = 'magenta';
      break;
    case 'coupon':
      text = 'Terminal vales de despensa';
      color = 'orange';
      break;
    case 'external_card':
      text = 'Externo - Tarjeta';
      color = 'geekblue';
      break;
    case 'external_cash':
      text = 'Externo - Efectvo';
      color = 'cyan';
      break;
    case 'link':
      text = 'Link';
      color = '#334457';
      break;
    default:
      text = 'Efectivo';
      color = 'green';
      break;
  }
  return <Tag color={color}>{text}</Tag>;
};

export const statusLabel = status => {
  let text = '';
  switch (status) {
    case 'completed':
    case 'delivered':
      text = 'Entregado';
      break;
    case 'new':
      text = 'Nuevo';
      break;
    case 'processing':
      text = 'Preparando';
      break;
    case 'ready':
      text = 'Listo para envío';
      break;
    case 'pending':
      text = 'En Camino';
      break;
    case 'canceled':
      text = 'Cancelado';
      break;
    case 'scheduled':
      text = 'Programado';
      break;
    default:
      text = 'En proceso';
      break;
  }

  return text;
};

export const statusFormatter = (status, order) => {
  let color = '';
  let text = '';
  switch (status) {
    case 'completed':
    case 'delivered':
      color = 'green';
      text = 'Entregado';
      break;
    case 'new':
      color = 'blue';
      text = 'Nuevo';
      break;
    case 'processing':
      color = 'yellow';
      text = 'Preparando';
      break;
    case 'ready':
      color = order.delivery_type === 'shipping' ? 'pink' : 'orange';
      text = order.delivery_type === 'shipping' ? 'Listo para envío' : 'Listo para recoger';
      break;
    case 'pending':
      color = 'purple';
      text = 'En Camino';
      break;
    case 'canceled':
      color = 'red';
      text = 'Cancelado';
      break;
    case 'scheduled':
      color = 'secondary';
      text = 'Programado';
      break;
    default:
      color = 'orange';
      text = 'En proceso';
      break;
  }

  return <Tag color={color}>{text}</Tag>;
};

export const stockToggle = (dataField, storeItem, allow_sync, updateStoreItem, updateLocalStoreItem) => {
  // updateLocalStoreItem(localStoreItem);

  const onChangeStock = async () => {
    await updateStoreItem({
      variables: {
        id: storeItem.id,
        storeItem: {
          isInStock: !storeItem.isInStock,
        },
      },
    });
    let localStoreItem = storeItem;
    localStoreItem.isInStock = !storeItem.isInStock;
    updateLocalStoreItem(localStoreItem);
  };
  return (
    <Switch
      id="isInStock"
      name="isInStock"
      checkedChildren="Disponible"
      unCheckedChildren="No Disponible"
      checked={storeItem.isInStock}
      disabled={allow_sync}
      onChange={onChangeStock}
    />
  );
};

export const stockFormatter = isInStock => {
  let color = '';
  let text = '';
  switch (isInStock) {
    case true:
      color = 'green';
      text = 'Disponible';
      break;
    case false:
      color = 'volcano';
      text = 'No Disponible';
      break;
    default:
      color = 'volcano';
      text = 'No Disponible';
      break;
  }

  return <Tag color={color}>{text}</Tag>;
};

export const priceFormatter = price => {
  return <span>{`$${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>;
};

export const customerFormatter = customerName => (
  // <Link to="pages/customer-details" className="font-weight-semi-bold">
  <span className="font-weight-semi-bold">{startCase(customerName)}</span>
  // </Link>
);

// ripeness
export const getLabelRipeness = ripeness_key => {
  let ripeness = '';
  switch (ripeness_key) {
    case 'one':
      ripeness = '- Verde';
      break;
    case 'two':
      ripeness = '- Mitad verde y mitad maduro';
      break;
    case 'three':
      ripeness = '- Maduro';
      break;
    default:
      ripeness = '';
      break;
  }
  return ripeness;
};

export const nameFormatter = (name, record, storeItems) => {
  return (
    <>
      <Typography.Text className="text-capitalize mb-0">
        {name && name.toLowerCase()} <span className="normal-case">{getLabelRipeness(record?.ripeness)}</span>
        <br />
        <span className="font-bold">{storeItems?.find(e => e.id === record?.store_item_id)?.code}</span>
      </Typography.Text>
    </>
  );
};

export const getPaymentMethodLabel = key => {
  let label = '';
  switch (key) {
    case 'cash':
      label = 'Efectivo';
      break;
    case 'card':
      label = 'Tarjeta';
      break;
    case 'card_against_delivery':
      label = 'Terminal con tarjeta';
      break;
    case 'transfer':
      label = 'Transferencia';
      break;
    case 'terminal':
      label = 'Terminal contra entrega';
      break;
    case 'coupon':
      label = 'Vales de despensa';
      break;
    default:
      break;
  }
  return label;
};

export const marginFormatter = (name, record) => {
  const fees = record.total >= 200 ? 15 : 29;
  const grossMargin = record?.store_order_items?.reduce((accumulator, object) => {
    const cost =
      object.default_measure === 'kg' && object.measure === 'pz'
        ? Number(object?.cost) * Number(object?.weight)
        : Number(object?.cost);
    return accumulator + Number(object.quantity) * (Number(object.price) - (cost || Number(object.price)));
  }, 0);
  const productDiscounts = record?.store_order_items?.reduce((accumulator, object) => {
    return accumulator + (Number(object.discount_cents) || 0);
  }, 0);
  const margin = grossMargin + fees - productDiscounts / 100;
  const percent = margin / record.total;
  return <span>{`${Math.round(percent * 100)}%`}</span>;
};
