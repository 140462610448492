import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { Typography } from '../../../../ui';
import RouteItem from '../RouteItem';
import PanelContext from 'context/PanelContext';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButton as FloatingActionButton } from '../DriverPanel/DriverPanel.styles';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import useQueryParams from 'hooks/useQueryParams';
import postMessage from '../../../../helpers/postMessage';

const updateRouteQuery = loader('queries/graphql/routes/updateRoute.graphql');

const RoutePanel = ({ route, refetch }) => {
  const { setTitle } = useContext(PanelContext);
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const [updateRoute, { loading }] = useMutation(updateRouteQuery);

  const itemRenderer = routeItem => {
    const storeOrder = routeItem.store_order;
    const actionContent = (
      <>
        {storeOrder?.status !== 'canceled' ? 
          <>Iniciar <ArrowRightOutlined /></>
          : <>Remover</>
        }
      </>
    );

    const action = () => {
      if(storeOrder?.status !== 'canceled'){
        const hasAppOrigin = queryParams.get('origin') === 'app';
        history.push(`${location.pathname}/detail${hasAppOrigin ? '?origin=app' : ''}`, { storeOrder });
  
        if (hasAppOrigin) {
          postMessage('START_TRACKING', {
            route,
            routeItem,
          });
        } else {
          setTimeout(() => {
            window.open(
              `https://www.google.com/maps/dir//${storeOrder?.shipping_coordinates?.replace(' ', '')}`,
              '_self',
              'location=yes',
            );
          }, 1000);
        }
      }else{
        finishRoute();
      }
      
    };

    return (
      <RouteItem
        key={`route-item-${routeItem.id}`}
        item={routeItem.store_order}
        action={action}
        status={storeOrder?.status}
        actionContent={actionContent}
        delivered={storeOrder?.status === 'delivered'}
      />
    );
  };

  const finishRoute = useCallback(async () => {
    if (route.id) {
      await updateRoute({
        variables: {
          id: route.id,
          route: {
            status: 'completed',
          },
        },
      });
      await refetch();
    }
  }, [route.id, updateRoute, refetch]);

  const showTripToBranch = () => {
    const hasAppOrigin = queryParams.get('origin') === 'app';
    if (hasAppOrigin) {
      postMessage('OPEN_MAP', route.store.coordinates?.replace(' ', ''));
    } else {
      window.open(`https://www.google.com/maps/dir//${route.store.coordinates?.replace(' ', '')}`, '_self');
    }
  };

  useEffect(() => {
    setTitle('Centro de entregas');
  }, [setTitle]);

  return (
    <div className="mb-12">
      <Row justify="center" className="mb-2">
        <Col span={24}>
          <Typography.Title level={5}>{route.driver?.name}</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text>
            Salida: <Moment format="ddd DD MMM HH:MM A">{route.start_at}</Moment>
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={24}>{route.route_items?.map(itemRenderer)}</Col>
      </Row>
      <FloatingActionButton
        shape="round"
        size="large"
        type="secondary"
        onClick={showTripToBranch}
        style={{ bottom: route?.route_items?.every(ri => ri?.store_order?.status === 'delivered') ? 75 : -48 }}
      >
        Ir a sucursal
      </FloatingActionButton>
      <FloatingActionButton
        shape="round"
        size="large"
        type="primary"
        loading={loading}
        onClick={finishRoute}
        style={{ bottom: route?.route_items?.every(ri => ri?.store_order?.status === 'delivered') ? 16 : -48 }}
      >
        Finalizar ruta
      </FloatingActionButton>
    </div>
  );
};

RoutePanel.propTypes = {
  route: PropTypes.object,
};

RoutePanel.defaultProps = {
  route: {},
};

export default RoutePanel;
