import { toast } from 'react-toastify';
import {
  SHOW_STORE_ORDER_MODAL,
  ADD_STORE_ORDER,
  ADD_STORE_ORDER_SUCCESS,
  ADD_STORE_ORDER_ERROR,
  GET_STORE_ORDERS,
  GET_STORE_ORDERS_SUCCESS,
  GET_STORE_ORDERS_ERROR,
  DELETE_STORE_ORDER,
  DELETE_STORE_ORDER_SUCCESS,
  DELETE_STORE_ORDER_ERROR,
  EDIT_STORE_ORDER,
  CANCEL_STORE_ORDER_MODAL,
  FILTER_STORE_ORDERS,
} from '../types';
import clientAxios from '../config/axios';
import { getItemFromStore } from '../helpers/utils';

// === SHOW STORE ORDER MODAL ===
export const showStoreOrderModal = show => {
  return dispatch => {
    dispatch(showModal(show));
  };
};

const showModal = show => ({
  type: SHOW_STORE_ORDER_MODAL,
  payload: show,
});

// === CANCEL STORE ORDER MODAL ===
export const cancelStoreOrderModal = hide => {
  return dispatch => {
    dispatch(hideModal(hide));
  };
};

const hideModal = hide => ({
  type: CANCEL_STORE_ORDER_MODAL,
  payload: hide,
});

// === CREATE NEW STORE ORDER ===
export const createNewStoreOrder = storeOrder => {
  return async dispatch => {
    dispatch(addStoreOrder());
    await clientAxios
      .post('/store-orders', storeOrder, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      })
      .then(result => {
        if (result.data) {
          dispatch(addStoreOrderSuccess(result.data));
          toast('Orden agregada correctamente');
        }
      })
      .catch(err => {
        console.log(err);
        console.log('err, creating a new store order');
        dispatch(addStoreOrderError(true));
      });
  };
};

const addStoreOrder = () => ({
  type: ADD_STORE_ORDER,
});

const addStoreOrderSuccess = storeOrder => ({
  type: ADD_STORE_ORDER_SUCCESS,
  payload: storeOrder,
});

const addStoreOrderError = state => ({
  type: ADD_STORE_ORDER_ERROR,
  payload: state,
});

// === GET STORE ORDERS ===
/**
 * Get store orders from endpoint using page, sizePerPage and sort
 * @param {Integer} page
 * @param {Integer} sizePerPage
 * @param {String} sort
 */
export const getStoreOrdersAction = (
  page = 1,
  sizePerPage = 999,
  sort = 'id',
  fromDate = null,
  toDate = null,
  qs = null,
  status = null,
) => {
  return async dispatch => {
    try {
      dispatch(getStoreOrders(page, sizePerPage, sort, fromDate, toDate));
      const storefront = getItemFromStore('o_s', {});
      let path = `/store-orders`;

      let options = {
        business: storefront.id,
      };
      if (fromDate) options['created_at_gte'] = fromDate.startOf('day').format();
      if (toDate) options['created_at_lte'] = toDate.endOf('day').format();
      if (qs && qs !== '') options['name_contains'] = qs;
      if (status) options['status'] = status;

      const countPath = `${path}/count?${new URLSearchParams(options).toString()}`;

      const count = await clientAxios.get(countPath, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      });
      const totalSize = count.data;
      const start = (page - 1) * sizePerPage;
      const limit = sizePerPage;

      options['_sort'] = sort;
      options['_start'] = start;
      options['_limit'] = limit;

      path += `?${new URLSearchParams(options).toString()}`;

      const result = await clientAxios.get(path, {
        headers: {
          Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
        },
      });
      if (result.data) {
        dispatch(getStoreOrdersSuccess(result.data, totalSize));
      }
    } catch (err) {
      console.error(err);
      console.error('err, creating a new store ORDER');
      dispatch(getStoreOrdersError(true));
    }
  };
};

const getStoreOrders = (page, sizePerPage, sort, fromDate, toDate) => ({
  type: GET_STORE_ORDERS,
  payload: { page, sizePerPage, sort, fromDate, toDate },
});

export const getStoreOrdersSuccess = (storeOrders, totalSize) => ({
  type: GET_STORE_ORDERS_SUCCESS,
  payload: storeOrders,
  totalSize: totalSize,
});

const getStoreOrdersError = state => ({
  type: GET_STORE_ORDERS_ERROR,
  payload: state,
});

// === DELETE STORE ORDER ===
export const deleteStoreOrderAction = id => {
  return async dispatch => {
    if (getItemFromStore('o_s', false)) {
      const storefront = getItemFromStore('o_s');
      dispatch(deleteStoreOrder(id));
      await clientAxios
        .delete(`/store-orders/${id}?business=${storefront.id}`, {
          headers: {
            Authorization: `Bearer ${getItemFromStore('jwt_user_token', null)}`,
          },
        })
        .then(() => {
          dispatch(deleteStoreOrderSuccess());
          toast('Orden eliminada correctamente');
        })
        .catch(err => {
          dispatch(deleteStoreOrderError());
          console.log(err);
        });
    } else {
      console.log('sorry, wait for it...');
    }
  };
};

const deleteStoreOrder = id => ({
  type: DELETE_STORE_ORDER,
  payload: id,
});

const deleteStoreOrderSuccess = () => ({
  type: DELETE_STORE_ORDER_SUCCESS,
});

const deleteStoreOrderError = () => ({
  type: DELETE_STORE_ORDER_ERROR,
  payload: true,
});

// === EDIT TORE ORDER ===
export const editStoreOrderAction = storeOrder => {
  return dispatch => {
    dispatch(editStoreOrder(storeOrder));
  };
};

const editStoreOrder = storeOrder => ({
  type: EDIT_STORE_ORDER,
  payload: storeOrder,
});

export const filterStoreOrdersAction = qs => {
  return async dispatch => {
    if (getItemFromStore('o_s', false)) {
      dispatch(filterStoreOrders(qs));
    } else {
      console.log('sorry, wait for it...');
    }
  };
};

const filterStoreOrders = qs => ({
  type: FILTER_STORE_ORDERS,
  payload: qs,
});
