import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import momentTZ from 'moment-timezone';
import { Layout, Row, Col, Button } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isTablet } from 'react-device-detect';
import { useAppContext } from 'context/Context';
import { checkIn, checkOut, isCheckinStarted } from 'queries/strapi/operations-schedule-audit';
import PanelContext from 'context/PanelContext';
import useQueryParams from 'hooks/useQueryParams';

const PanelNavigationContent = styled(Layout.Header)`
  &.ant-layout-header {
    background: ${props => props.theme.colors.secondary};
    box-shadow: ${props => props.theme.miscellany.boxShadow};
    padding: ${props => (props.breakpoint === 'xs' ? '0px 25px' : '0px 50px')};
  }
`;

const LogoText = styled.p`
  color: ${props => props.theme.colors.white};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: ${props => (props.breakpoint === 'xs' ? '0.75rem' : '1.25rem')};
  margin: 0;
`;

const PanelText = styled.h6`
  color: ${props => props.theme.colors.white};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-size: ${props => (props.breakpoint === 'xs' ? '0.9rem' : '1.325rem')};
  text-align: center;
  margin: 0;
`;

const CloseButton = styled(Button)`
  position: absolute !important;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: transparent !important;
  color: ${props => props.theme.colors.white} !important;
  border-color: transparent !important;
  &.ant-btn:after {
    animation: 0s !important;
  }
`;

const Logo = ({ breakpoint }) => <LogoText breakpoint={breakpoint}>Orchata</LogoText>;
const PanelTitle = ({ title, breakpoint }) => <PanelText breakpoint={breakpoint}>{title}</PanelText>;

const PanelNavigation = ({ title }) => {
  const [isCheckIn, setIsCheckIn] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const { currentDriver: driver } = useContext(PanelContext);

  const breakpoint = useBreakpoint();
  const { storefront } = useAppContext();
  const currentBreakpoint = useMemo(() => {
    if (breakpoint.xxl) return 'xxl';
    if (breakpoint.xl) return 'xl';
    if (breakpoint.lg) return 'lg';
    if (breakpoint.md) return 'md';
    if (breakpoint.sm) return 'sm';
    return 'xs';
  }, [breakpoint]);
  const queryParams = useQueryParams();
  const history = useHistory();

  const colPrimary = storefront?.id === 217 ? 12 : 16;
  const colSecondary = storefront?.id === 217 ? 6 : 4;

  const checkInOut = async () => {
    if (isCheckIn) {
      const result = await checkOut({
        id: schedule?.id,
        end: momentTZ().tz('America/Monterrey').format('YYYY-MM-DD HH:mm'),
      });
      if (result.status === 200) {
        setIsCheckIn(!isCheckIn);
      }
    } else {
      const result = await checkIn({
        business: driver?.business?.id,
        store: driver?.stores?.[0]?.id,
        related_type: 'driver',
        related_id: driver.id,
        start: momentTZ().tz('America/Monterrey').format('YYYY-MM-DD HH:mm'),
        name: driver.name,
      });
      if (result.status === 200) {
        setSchedule(result.data);
        setIsCheckIn(!isCheckIn);
      }
    }
  };

  const handleClose = () => {
    if (queryParams.get('origin') === 'app') {
      return history.replace('/panel/driver?origin=app');
    }
    history.replace(isTablet ? '/auth/logout' : '/');
  };

  // preload categories
  const checkIfCheckinStarted = useCallback(() => {
    (async () => {
      if (driver) {
        const result = await isCheckinStarted({
          business: driver?.business?.id,
          store: driver?.stores?.[0]?.id,
          related_type: 'driver',
          related_id: driver?.id,
          start: momentTZ().tz('America/Monterrey').format('YYYY-MM-DD'),
        });
        if (result?.status === 200 && result?.data?.length > 0) {
          setIsCheckIn(true);
          setSchedule(result.data?.[0]);
        }
      }
    })();
  }, [driver]);

  useEffect(checkIfCheckinStarted, [checkIfCheckinStarted]);

  return (
    <PanelNavigationContent breakpoint={currentBreakpoint}>
      <Row>
        <Col xs={colSecondary} sm={8}>
          <Logo breakpoint={currentBreakpoint} />
        </Col>
        <Col xs={colPrimary} sm={7}>
          <PanelTitle title={title} breakpoint={currentBreakpoint} />
        </Col>
        <Col xs={colSecondary} sm={8}>
          {storefront?.id === 217 ? (
            <Button onClick={checkInOut} type="primary" size="sm">
              {isCheckIn ? 'Terminar turno' : 'Iniciar turno'}
            </Button>
          ) : (
            <CloseButton onClick={handleClose}>Cerrar</CloseButton>
          )}
        </Col>
      </Row>
    </PanelNavigationContent>
  );
};

export default PanelNavigation;
