import styled from 'styled-components';
import Button from '../../../../ui/button/Button';

export const ActionButton = styled(Button)`
  height: 48px !important;
  position: fixed !important;
  width: calc(100vw - 32px) !important;
  left: 16px !important;
  right: 16px !important;
  bottom: -48px;
  transition: all 200ms linear;
`;

export const NoEmptyList = styled.div`
  .ant-list-empty-text {
    ${props => (!props.show ? 'display: none;' : '')}
  }
`;
