import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import { getBaseApiUrl } from '../helpers/globals';
import { getItemFromStore } from '../helpers/utils';
import { setContext } from '@apollo/client/link/context';

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: `${getBaseApiUrl()}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getItemFromStore('jwt_user_token', null);
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default client;
