const PickupTimes = {
  7: '7:00 am - 8:00 am',
  8: '8:00 am - 9:00 am',
  9: '9:00 am - 10:00 am',
  10: '10:00 am - 11:00 am',
  11: '11:00 am - 12:00 pm',
  12: '12:00 pm - 1:00 pm',
  13: '1:00 pm - 2:00 pm',
  14: '2:00 pm - 3:00 pm',
  15: '3:00 pm - 4:00 pm',
  16: '4:00 pm - 5:00 pm',
  17: '5:00 pm - 6:00 pm',
  18: '6:00 pm - 7:00 pm',
};

export default PickupTimes;
