import { Tag as AntTag } from 'antd';
import styled from 'styled-components';
import { percentToHex } from '../../theme';

const Tag = styled(AntTag)`
  color: ${props => props.theme.colors[props.color || 'blue']};
  border-color: ${props => props.theme.colors[props.color || 'blue']};
  background: ${props => `${props.theme.colors[props.color || 'blue']}${percentToHex(20)}`};
  padding: 6px 12px; // TODO: Move this to theme.spacing
  border-radius: 40px; // TODO: Move this to theme.miscellany
  font-size: 0.75rem;
`;

export default Tag;
