import { LogoutOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Menu, Row, Select } from 'antd';
import { default as React, useContext } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/img/logos/logo.png';
import AppContext from '../../context/Context';
import routes from '../../routes';

const Sider = styled(Layout.Sider)`
  min-height: 100vh;
  max-height: 100vh;
  width: 250px;
  overflow-y: hidden;
`;

const SiderLogoContainer = styled.div`
  width: 250px;
  height: 60px;
  background: ${props => props.theme.colors.white};
  padding-left: 24px;
  display: flex;
  align-items: center;
`;

const SiderContent = styled(Menu)`
  width: 250px !important;
  height: calc(100vh - 60px - 120px) !important;
  overflow-y: auto !important;
  padding-bottom: 8px !important;
`;

const SiderControls = styled(Row)`
  background: ${props => props.theme.colors.white} !important;
  width: 250px !important;
  height: 120px;
  padding: 16px 0px;
`;

const NavbarVertical = ({ location, collapsed }) => {
  const { userRoleType, storefront, stores, currentStore, setCurrentCity, setCurrentStore } = useContext(AppContext);

  const logoHeight = '36px';

  const evalUserRole = r => {
    return (
      ((!r.review && r.roles.includes(userRoleType)) || (r.review && userRoleType === 'orchatateam')) &&
      (!r.businesses || r.businesses.includes(storefront.Name?.trim())) &&
      (!r.businessConditions || r.businessConditions.every(c => storefront[c]))
    );
  };

  const evalIsSaas = r => {
    return storefront.isSaas || (!storefront.isSaas && !r.onlyForSaas);
  };

  return (
    <Sider collapsible collapsed={collapsed} width={250} breakpoint="lg" collapsedWidth="0" theme="light">
      <SiderLogoContainer>
        <img src={logo} alt="Logo" style={{ maxHeight: logoHeight }} />
      </SiderLogoContainer>
      <SiderContent
        theme="light"
        mode="vertical"
        selectedKeys={[routes.find(menuItem => menuItem.to === location.pathname)?.id]}
      >
        {routes.map(r =>
          evalUserRole(r) && evalIsSaas(r) ? (
            <Menu.Item key={r.id} icon={r.icon} className="pl-4">
              <NavLink to={r.to} className="pl-2 md:text-md font-semibold">
                {r.name}
              </NavLink>
            </Menu.Item>
          ) : null,
        )}
      </SiderContent>
      <SiderControls>
        <>
        {(userRoleType === 'businessowner' || userRoleType === 'orchatateam') && stores && stores.length > 0 && 
          (<Col offset={2} span={18}>
            {userRoleType === 'orchatateam' ? null: (
              <div className="overflow-hidden">
                <Button className="font-semibold text-lg pt-0" type="link">
                  {storefront.Name}
                  {(userRoleType === 'storemanager' ||
                    userRoleType === 'storeoperator' ||
                    userRoleType === 'operationsmanager') &&
                    !!currentStore && <span className="text-xs">&nbsp;({currentStore?.name})</span>}
                </Button>
              </div>
            )}
          </Col>)}
          {storefront?.id === 217 && (userRoleType === 'businessowner' || userRoleType === 'orchatateam') && stores && stores.length > 0 && (
            <Col offset={2} span={18}>
              <Select
                defaultValue={null}
                onChange={ids => setCurrentCity(ids)}
                className="container font-semibold text-lg w-100 text-left overflow-hidden"
              >
                <Select.Option key={0} value={null}>
                  Todas las ciudades
                </Select.Option>
                <Select.Option key={1} value={[59, 60, 61, 62, 63, 75]}>
                  Monterrey
                </Select.Option>
                <Select.Option key={2} value={[77, 79]}>
                  Guadalajara
                </Select.Option>
                <Select.Option key={3} value={[81]}>
                  Merida
                </Select.Option>
              </Select>
            </Col>
          )}
          {(userRoleType === 'businessowner' || userRoleType === 'orchatateam') && stores && stores.length > 0 && (
            <Col offset={2} span={18}>
              <Select
                defaultValue={null}
                onChange={id => setCurrentStore(stores.filter(store => store.id === id)[0])}
                className="container font-semibold text-lg w-100 text-left overflow-hidden"
              >
                <Select.Option key={0} value={null}>
                  Todas las sucursales
                </Select.Option>
                {stores.map(s => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
          <Col span={4} className="overflow-hidden">
            <Link to="/auth/logout">
              <Button type="link" icon={<LogoutOutlined />}></Button>
            </Link>
          </Col>
        </>
      </SiderControls>
    </Sider>
  );
};

export default withRouter(NavbarVertical);
