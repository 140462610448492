export const identifyWithSegment = params => {
  if (!params.storefront.isTrial & !params.storefront.is_demo) {
    window.analytics.identify(params.user_id, params.properties);
  }
};

export const trackWithSegment = params => {
  if (!params.storefront.isTrial & !params.storefront.is_demo) {
    window.analytics.track(params.event, {
      business_id: params.storefront.id,
      business_name: params.storefront.Name,
      status: params?.status,
      no_order: params?.no_order,
      customer_name: params?.customer_name,
    });
  }
};
