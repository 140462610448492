import {
  GET_PRODUCTS_RECOMMENDATIONS,
  GET_PRODUCTS_RECOMMENDATIONS_SUCCESS,
  GET_PRODUCTS_RECOMMENDATIONS_ERROR,
  MARK_AS_READ_PRODUCT_RECOMMENDATION,
  MARK_AS_READ_PRODUCT_RECOMMENDATION_SUCCESS,
  MARK_AS_READ_PRODUCT_RECOMMENDATION_ERROR,
  FILTER_PRODUCTS_RECOMMENDATIONS,
  TOOGLE_READED_PRODUCTS_RECOMMENDATIONS,
} from '../types';

// each reducer has it's own state
export const initialState = {
  productsRecommendations: [],
  error: null,
  loading: false,
  collapse: false,
  readed: false,
  qs: null,
  page: 1,
  sizePerPage: 20,
  sort: 'id:desc',
  totalSize: null,
  markAsRead: null,
};

/**
 * Manage states changes for products recommendations
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_RECOMMENDATIONS:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        sizePerPage: action.payload.sizePerPage,
        sort: action.payload.sort ? action.payload.sort : initialState.sort,
        readed: action.payload.readed,
      };
    case GET_PRODUCTS_RECOMMENDATIONS_ERROR:
    case MARK_AS_READ_PRODUCT_RECOMMENDATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRODUCTS_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        totalSize: action.totalSize,
        productsRecommendations: action.payload,
      };
    case MARK_AS_READ_PRODUCT_RECOMMENDATION:
      return {
        ...state,
        markAsRead: action.payload,
      };
    case MARK_AS_READ_PRODUCT_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        markAsRead: null,
        productsRecommendations: state.productsRecommendations.filter(c => c.id !== action.payload.id),
      };
    case FILTER_PRODUCTS_RECOMMENDATIONS:
      return {
        ...state,
        qs: action.payload.trim().toLowerCase(),
      };
    case TOOGLE_READED_PRODUCTS_RECOMMENDATIONS:
      return {
        ...state,
        readed: action.payload,
      };
    default:
      return state;
  }
};
