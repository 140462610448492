import colors from './colors';
import miscellany from './miscellany';
import typography from './typography';
export * from './helpers';

const theme = {
  colors,
  miscellany,
  typography,
};

export default theme;
