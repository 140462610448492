import React, { useState } from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Row, Collapse } from 'antd';
import { Button, Tag, Typography } from '../../../ui';
import { driverPaymentMethod } from 'helpers/utils';
import { breakName } from 'components/table/Formatters';
import { FlexCol, Info, RouteCard, Collapser } from './routeComponents';

const { Title, Text } = Typography;
const { Panel } = Collapse;

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

const mapUrlBuilder = coordinates => {
  const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
  const zoom = 13;
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  return `${baseUrl}?center=${coordinates}&zoom=${zoom}&size=350x175&markers=${coordinates}&key=${apiKey}`;
};

const RouteItem = ({ item, action, selected, actionContent, delivered, disabled = false, status }) => {
  const [expanded, setExpanded] = useState(undefined);

  const maximum_sample_of_products = 25;

  const toggleMap = () => {
    setExpanded(expanded ? undefined : ['1']);
  };

  const triggerAction = () => {
    action(item);
  };

  const refreshPage = () => {
    window.location.reload();
  }

  return (
    <RouteCard onClick={toggleMap}>
      <Row>
        <FlexCol span={16}>
          <Title style={{ width: '100%' }} level={5}>
            #{item?.no_order}
          </Title>
          <Info>{breakName(item?.name)}</Info>
          <Title style={{ width: '100%' }} level={5}>
            {driverPaymentMethod(item?.payment_method)}: {currencyFormatter(item?.total)}
          </Title>
          <Info>{item?.shippingAddress}</Info>
          {item?.shipping_extra ?
            <Info class="font-semibold">{item?.shipping_extra}</Info>
            : null}
        </FlexCol>
        <FlexCol span={8}>
          {item?.shipping_distance && (
            <Text className="mb-4">
              <EnvironmentOutlined /> {item?.shipping_distance}
            </Text>
          )}
          {delivered ? (
            <Tag color="success">Entregado</Tag>
          ) :
            status !== 'new' || status !== 'processing' ?
              <Button type="primary" color={status === 'canceled' ? "red" : "blue"} shape="round" onClick={triggerAction} size="large" disabled={disabled}>
                {actionContent ? actionContent : selected ? 'Remover' : 'Aceptar'}
              </Button> :
              <Button type="primary" color="green" shape="round" onClick={refreshPage}>Actualizar</Button>
          }
        </FlexCol>
      </Row>
      <Row>
        <FlexCol span={18}>
          <div className="w-full flex flex-row space-x-2 overflow-auto items-start">
            {item?.store_order_items ? item?.store_order_items.slice(0, maximum_sample_of_products).map((storeItem, i) => (
              <img key={i} className="w-24" alt={`item-${storeItem.image_url}`} src={storeItem.image_url} />
            )) : null}
          </div>
        </FlexCol>
      </Row>
      {item?.store_order_items?.length > maximum_sample_of_products &&
        <Row>
          <FlexCol span={18}>
            <div className="w-full">
              <span>Mostrando solo los primeros {maximum_sample_of_products} productos</span>
            </div>
          </FlexCol>
        </Row>
      }
      <Row>
        {
          status === 'new' || status === 'processing' ?
            <span className="text-red-700 font-semibold">El pedido lo esta terminando de preparar tu compañero</span> : ''
        }
        {
          status === 'canceled' ?
            <span className="text-red-700 font-semibold">El pedido ha sido cancelado, cuando termines la ruta cancelalo</span> : ''
        }
      </Row>
      <Row>
        <FlexCol className="pt-2" span={24}>
          <Collapser activeKey={expanded} ghost>
            <Panel key="1" showArrow={false} header={null} forceRender>
              <img alt={`map-thumbnail-${item?.no_order}`} src={mapUrlBuilder(item?.shipping_coordinates)} />
            </Panel>
          </Collapser>
        </FlexCol>
      </Row>
    </RouteCard>
  );
};

export default RouteItem;
