import { createContext } from 'react';

const PanelContext = createContext({
  title: '',
  setTitle: null,
  currentDriver: {
    id: 0,
    name: '',
    stores: [],
    business: { id: 0},
    delivery_areas: [],
    whatsapp: ''
  },
  setCurrentDriver: null
});

export default PanelContext;
