import uniqBy from 'lodash/uniqBy';
import {
  SHOW_STORE_ORDER_MODAL,
  ADD_STORE_ORDER,
  ADD_STORE_ORDER_SUCCESS,
  ADD_STORE_ORDER_ERROR,
  GET_STORE_ORDERS,
  GET_STORE_ORDERS_SUCCESS,
  GET_STORE_ORDERS_ERROR,
  DELETE_STORE_ORDER,
  DELETE_STORE_ORDER_SUCCESS,
  DELETE_STORE_ORDER_ERROR,
  EDIT_STORE_ORDER,
  CANCEL_STORE_ORDER_MODAL,
  FILTER_STORE_ORDERS,
} from '../types';

// each reducer has it's own state
export const initialState = {
  storeOrders: [],
  storeCustomers: [],
  error: null,
  loading: false,
  collapse: false,
  qs: null,
  page: 1,
  sizePerPage: 10,
  fromDate: null,
  toDate: null,
  sort: 'id:desc',
  totalSize: null,
  deleteStoreOrder: null,
  updateStoreOrder: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_STORE_ORDER_MODAL:
      return {
        ...state,
        collapse: action.payload,
      };
    case GET_STORE_ORDERS:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        sizePerPage: action.payload.sizePerPage,
        sort: action.payload.sort ? action.payload.sort : initialState.sort,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
    case ADD_STORE_ORDER:
      return {
        ...state,
        loading: true,
      };
    case ADD_STORE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        storeOrders: [...state.storeOrders, action.payload],
        collapse: false,
      };
    case ADD_STORE_ORDER_ERROR:
    case GET_STORE_ORDERS_ERROR:
    case DELETE_STORE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CANCEL_STORE_ORDER_MODAL:
      return {
        ...state,
        loading: action.payload,
        error: action.payload,
        deleteStoreOrder: null,
        updateStoreOrder: null,
        collapse: action.payload,
      };
    case GET_STORE_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        totalSize: action.totalSize,
        storeOrders: action.payload,
        storeCustomers: uniqBy(action.payload, 'whatsapp'),
      };
    case DELETE_STORE_ORDER:
      return {
        ...state,
        deleteStoreOrder: action.payload,
      };
    case DELETE_STORE_ORDER_SUCCESS:
      return {
        ...state,
        storeOrders: state.storeOrders.filter(storeItem => storeItem.id !== state.deleteStoreOrder),
        deleteStoreOrder: null,
      };
    case EDIT_STORE_ORDER:
      return {
        ...state,
        updateStoreOrder: action.payload,
      };
    case FILTER_STORE_ORDERS:
      return {
        ...state,
        qs: action.payload.trim().toLowerCase(),
      };
    default:
      return state;
  }
};
