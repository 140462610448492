import React, { useState, useContext, useRef, useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { getStoreOrdersSuccess } from '../../../actions/storeOrderActions';
import { columns } from './PanelHelpers';
import { loader } from 'graphql.macro';
import { camelCase, sortBy } from 'lodash';
import AppContext from 'context/Context';
import { Table, Modal } from 'ui';
import { Empty } from 'antd';
import moment from 'moment';
import TicketPrintView from 'components/dashboard/orders/detail/TicketPrintView';
import ChangeOrderTotal from './ChangeOrderTotal';
import { useMutation, useQuery } from '@apollo/client';
const findStoreOrdersQuery = loader('queries/graphql/storeOrders/findStoreOrdersWithItems.graphql');
const updateStoreOrderMutation = loader('queries/graphql/storeOrders/updateStoreOrder.graphql');
const updateDeviceQuery = loader('queries/graphql/devices/updatePrintDevice.graphql');

// === DATE FILTER CONSTANTS ===
const TIME_UNIT = 'day'; // Can be: minutes, hours, days, week, month, year
const TIME_UNIT_QUANTITY = 7;

const OrdersTable = ({ deliveryType, pickers, setHasPickers, setCurrentOrder, currentOrder }) => {
  const dispatch = useDispatch();
  const { storeOrdersRefetchRef, currentStore, storefront, userRoleType } = useContext(AppContext);

  const [storeOrders, setStoreOrders] = useState([]);
  const [sort, setSort] = useState('created_at:desc');
  const [filters, setFilters] = useState({});
  const [currentTime, setCurrentTime] = useState(moment());
  const printRef = useRef();
  const print = useReactToPrint({
    copyStyles: false,
    content: () => printRef.current,
  });
  const urlMaps = useMemo(() => `http://www.google.com/maps/place/${currentOrder?.shipping_coordinates}`, [
    currentOrder,
  ]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [originalTotal, setOriginalTotal] = useState('');
  const [total, setTotal] = useState('');
  const inputRef = useRef();
  const [updateStoreOrder] = useMutation(updateStoreOrderMutation);
  const [updateDevice] = useMutation(updateDeviceQuery);
  const variables = Object.assign(
    {
      business: storefront.id,
      sort,
      fromDate: moment().startOf('day').subtract(TIME_UNIT_QUANTITY, TIME_UNIT).toDate(),
    },
    currentStore ? { store: currentStore.id } : {},
    deliveryType ? { deliveryType, sort: 'pickup_date_time:asc' } : {},
    filters,
  );
  const {
    data: rawStoreOrders,

    loading: storeOrdersLoading,
    refetch: refetchStoreOrders,
  } = useQuery(findStoreOrdersQuery, { variables });

  const onTableChange = ({ sortField, sortOrder, filters }) => {
    if (sortField && sortOrder) {
      setSort(`${sortField}:${sortOrder}`);
    }

    if (filters) {
      const nonEmptyFilters = {};

      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          nonEmptyFilters[camelCase(key)] = filters[key];
        }
      });

      setFilters(nonEmptyFilters);
    }
  };

  const handleOrderTotal = order => {
    setCurrentOrder(order);
    setTotal(order.total);
    setOriginalTotal(order.total);
    setModalVisible(true);
  };

  const handleOrderTotalChange = value => {
    setTotal(value);
  };

  const handleBlur = () => {
    if (!total) {
      setTotal(originalTotal);
    }
  };

  const handleFocus = () => {
    setTotal('');
  };

  const cancelTotalChange = () => {
    setTotal('');
    inputRef.current.focus();
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const saveOrder = async () => {
    try {
      // fake status change for a quick ux feedback
      changeStatus(currentOrder.id, 'ready');
      setModalLoading(true);
      await updateStoreOrder({
        variables: {
          id: currentOrder.id,
          storeOrder: {
            total,
            status: 'ready',
            edited: total !== originalTotal,
          },
        },
      });
      setModalLoading(false);
      closeModal();
    } catch (error) {
      console.error(error);
      setModalLoading(false);
    }
  };

  const today = useMemo(() => moment().startOf('day'), []);

  const cloudPrint = async order => {
    const sendPrint = order;
    if (sendPrint) {
      let store_printer = '';
      if (sendPrint.store?.print_device?.id) {
        //the printer is assigned to the store
        store_printer = sendPrint.store.print_device.id;
      } else if (sendPrint.business?.print_device?.id) {
        //the printer is assigned to the business
        store_printer = sendPrint.business.print_device.id;
      } else {
        //no printer asssigned
      }

      await updateDevice({
        variables: {
          id: store_printer,
          deviceData: {
            Printing: 7,
            PrintingData: sendPrint,
          },
        },
      });
    }
  };

  const handlePrint = order => {
    setCurrentOrder(order);
    if (!!order?.business?.print_device?.id || !!order?.store?.print_device?.id) {
      cloudPrint(order);
    } else {
      print();
    }
  };

  const evalOrderInDanger = useCallback(
    order => {
      const deliveryEndDate =
        order.delivery_type === 'shipping' && order.delivery_day && order.delivery_time
          ? moment(`${order.delivery_day.replace(new RegExp('-', 'g'), '/')} ${order.delivery_time.split(' - ')[1]}`)
          : moment(order.pickup_date_time);
      const diff = deliveryEndDate.diff(currentTime, 'minutes');
      return diff <= 40 && order.status !== 'completed' && order.status !== 'delivered' && order.status !== 'canceled'
        ? 'danger'
        : '';
    },
    [currentTime],
  );

  const changeStatus = (id, status) => {
    const orders = [...storeOrders];
    const order = orders.find(storeOrder => storeOrder.id === id);
    if (order) {
      order.status = status;
      setStoreOrders(orders);
    }
  };

  const processStoreOrders = () => {
    const storeOrders = rawStoreOrders?.storeOrders;
    let processedOrders = storeOrders
      ? JSON.parse(JSON.stringify(storeOrders))
          .filter(order => {
            // Display orders completed today
            if (
              moment(order.updated_at).startOf('day').diff(today, TIME_UNIT) === 0 &&
              (order.status === 'completed' || order.status === 'delivered')
            ) {
              return true;
            }
            // Display not completed orders from past X days
            if (
              moment(order?.created_at).startOf('day').diff(today, TIME_UNIT) >= -TIME_UNIT_QUANTITY &&
              moment(
                order.delivery_type === 'pickup'
                  ? order.pickup_date_time
                  : `${order.delivery_day?.replace(new RegExp('-', 'g'), '/')} ${order.delivery_time?.split(' - ')[0]}`,
              )
                .startOf('day')
                .diff(today, TIME_UNIT) <= 0 &&
              order.status !== 'delivered' &&
              order.status !== 'completed' &&
              order.status !== 'canceled'
            ) {
              return true;
            }
            return false;
          })
          .map(order => {
            // Status
            if (order.status === 'new') {
              order.statusPriority = 1;
            }
            if (order.status === 'processing') {
              order.statusPriority = 2;
            }
            if (order.status === 'ready') {
              order.statusPriority = 3;
            }
            if (order.status === 'pending') {
              order.statusPriority = 4;
            }
            if (order.status === 'delivered' || order.status === 'completed') {
              order.statusPriority = 5;
            }
            // Delivery
            if (order.delivery_type === 'shipping' && order.delivery_time && order.delivery_day) {
              order.deliveryDate = moment(`${order.delivery_day} ${order.delivery_time.split(' - ')[0]}`);
            } else {
              order.deliveryDate = moment(order.pickup_date_time);
            }
            return order;
          })
      : [];

    // First sort by statusPriority, then by delivery date.
    processedOrders = sortBy(processedOrders, ['statusPriority', 'deliveryDate']);
    const totalSize = rawStoreOrders?.storeOrdersConnection?.aggregate?.count;
    dispatch(getStoreOrdersSuccess(storeOrders, totalSize));
    storeOrdersRefetchRef.current = refetchStoreOrders;

    // Update storeOrders
    setStoreOrders(processedOrders);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime(moment());
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(processStoreOrders, [rawStoreOrders, dispatch, refetchStoreOrders, storeOrdersRefetchRef, today]);

  return (
    <>
      <Table
        columns={columns({
          handlePrint,
          handleOrderTotal,
          userRoleType,
          pickers,
          setHasPickers,
          changeStatus,
          setCurrentOrder,
        })}
        dataSource={storeOrders}
        bordered
        pagination={false}
        loading={storeOrdersLoading}
        locale={{
          emptyText: <Empty description="No hay pedidos" />,
        }}
        onChange={onTableChange}
        scroll={{ y: 'calc(100vh - 200px)', x: 800 }}
        rowClassName={evalOrderInDanger}
      />
      <TicketPrintView order={currentOrder} printComponentTicketRef={printRef} urlMaps={urlMaps} />
      <Modal
        visible={modalVisible}
        loading={modalLoading}
        okText="Confirmar total"
        cancelText="Editar total"
        onOk={saveOrder}
        onCancel={closeModal}
        footerSize="large"
        cancelButtonProps={{
          onClick: cancelTotalChange,
        }}
        closable={true}
      >
        <ChangeOrderTotal
          ref={inputRef}
          placeholder={originalTotal}
          value={total}
          onChange={handleOrderTotalChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </Modal>
    </>
  );
};

export default OrdersTable;
