import { SET_FROM_DATE, SET_TO_DATE } from '../types';
import moment from 'moment';

// each reducer has it's own state
export const initialState = {
  fromDate: moment().startOf('month'),
  toDate: moment().add(1,'days'),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.payload,
      };
    case SET_TO_DATE:
      return {
        ...state,
        toDate: action.payload,
      };
    default:
      return state;
  }
};
