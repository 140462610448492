import React from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const SettingsFooter = ({ onClose, isLoading, handleProfileSettings }) => {
  return (
    <div
      style={{
        textAlign: 'right',
      }}
    >
      <Button onClick={onClose} style={{ marginRight: 8 }}>
        Cancelar
      </Button>
      <Button onClick={handleProfileSettings} icon={isLoading && <LoadingOutlined />} type="primary">
        Guardar
      </Button>
    </div>
  );
};

export default SettingsFooter;
