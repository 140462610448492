import React, { useContext, useEffect } from 'react';
import Query from '../components/query/index';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import DriverPanel from '../components/panel/routes/DriverPanel';
import RoutePanel from '../components/panel/routes/RoutePanel/index';
import moment from 'moment-timezone';
import AppContext from 'context/Context';

const findDriverByTokenQuery = loader('queries/graphql/drivers/findDriverByToken.graphql');
const findStoreOrdersQuery = loader('queries/graphql/storeOrders/findStoreOrders.graphql');
const findRoutesQuery = loader('queries/graphql/routes/findRoutes.graphql');

const findRouteQuery = loader('queries/graphql/routes/findRoute.graphql');
const DriverPanelContainer = () => {
  const { whatsapp } = useParams();
  const { storeOrdersRoutesRefetchRef } = useContext(AppContext);

  const [findDrivers, { data: { drivers } = { drivers: [] } }] = useLazyQuery(findDriverByTokenQuery, {
    fetchPolicy: 'network-only',
  });
  const [findRoutes, { data: { routes } = { routes: [] }, refetch }] = useLazyQuery(findRoutesQuery, {
    fetchPolicy: 'network-only',
    pollInterval: 20000,
  });

  useEffect(() => {
    if (whatsapp) findDrivers({ variables: { whatsapp } });
  }, [whatsapp, findDrivers]);

  useEffect(() => {
    if (drivers.length > 0 && drivers[0].business && drivers[0].business.id)
      findRoutes({ variables: { businessId: drivers[0].business.id, driverId: drivers[0].id, status: ['started', 'canceled'] } });
  }, [drivers, findRoutes]);

  const queries = {
    findRoute: {
      query: findRouteQuery,
      variables: {
        id: routes?.[0]?.id,
      },
    },
    findStoreOrders: {
      query: findStoreOrdersQuery,
      variables: {
        status: ['new', 'processing', 'ready'],
        deliveryDay: moment()
          .tz(drivers?.[0]?.business?.timezone || 'America/Monterrey')
          ?.format('YYYY-MM-DD'),
        deliveryAreas: drivers?.[0]?.delivery_areas?.map(d => parseInt(d.id)),
        route_item_null: true,
        limit: 100,
      },
      fetchPolicy: 'network-only',
      pollInterval: 20000,
    },
  };

  return (
    <>
      {whatsapp &&
        drivers.length > 0 &&
        (routes.length === 0 ? (
          <Query {...queries['findStoreOrders']}>
            {({ data: { storeOrders } = { storeOrders: [] } }, state, storeOrdersRefetch) => {
              storeOrdersRoutesRefetchRef.current = storeOrdersRefetch;
              return <DriverPanel driver={drivers?.[0]} storeOrders={storeOrders} refetch={refetch} />;
            }}
          </Query>
        ) : (
          <Query {...queries['findRoute']}>
            {({ data: { routes } = { routes: [] } }, state, refetchRoute) => {
              return <RoutePanel route={routes?.[0]} refetch={refetch} refetchRoute={refetchRoute} />;
            }}
          </Query>
        ))}
    </>
  );
};

export default DriverPanelContainer;
