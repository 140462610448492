import clientAxios from '../../config/axios';

export const isCheckinStarted = async ({ business, store, related_type, related_id, start }) => {
  return await clientAxios.get(
    `/operations-schedule-audits?business=${business}&store=${store}&related_type=${related_type}&related_id=${related_id}&start_gte=${start}&end_null=true`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const checkIn = async ({ business, store, related_type, related_id, start, name }) => {
  return await clientAxios.post(
    `/operations-schedule-audits`,
    { business, store, related_type, related_id, start, name },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const checkOut = async ({ id, end }) => {
  return await clientAxios.put(
    `/operations-schedule-audits/${id}`,
    { end },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};