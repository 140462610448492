import clientAxios from '../../config/axios';

export const getPickersByBusiness = async (business_id, token) => {
  try {
    if (business_id) {
      let query = `?business=${business_id}`;
      const result = await clientAxios.get(`/pickers${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};