import momentTZ from 'moment-timezone';
import moment from 'moment';

export const momentHelper = ({ timezone = 'America/Monterrey', locale } = { timezone: null, locale: null }, date = moment()) => {
  if (!timezone || !locale) {
    console.error('Initialize with timezone and locale');
    return moment();
  } else {
    return moment(momentTZ.tz(date, timezone)).locale(locale);
  }
};
