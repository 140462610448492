import PanelContext from 'context/PanelContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WhatsAppOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Row, Col, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import { Modal, Typography } from '../../../ui';
import { ActionButton, CardItemDivider, RouteCard } from './routeComponents';
import { humanizePaymentMethod, humanizePhoneNumber } from 'helpers/utils';
import { ActionButton as FloatingActionButton } from './DriverPanel/DriverPanel.styles';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DoubleLocation from 'assets/img/icons/double-location.svg';
import { driverNotifyCustomer } from 'queries/strapi/store-order';
import useQueryParams from 'hooks/useQueryParams';
import postMessage from 'helpers/postMessage';

const updateStoreOrderQuery = loader('queries/graphql/storeOrders/updateStoreOrder.graphql');
const { Title, Text, Paragraph } = Typography;

const CardItem = ({ label, info }) => {
  return (
    <Row>
      <Col span={12}>
        <Text>{label}</Text>
      </Col>
      <Col span={12}>
        <Paragraph align="right">{info}</Paragraph>
      </Col>
      <CardItemDivider />
    </Row>
  );
};

const Actions = ({ whatsapp, shippingCoordinates, countryCode = 'mx', hasAppOrigin }) => {
  const handleMap = () => {
    if (hasAppOrigin) {
      postMessage('OPEN_MAP', shippingCoordinates?.replace(' ', ''));
    } else {
      window.open(`https://www.google.com/maps/dir//${shippingCoordinates?.replace(' ', '')}`, '_self');
    }
  };

  const handleWhatsapp = () => {
    window.open(`tel:${humanizePhoneNumber(whatsapp, countryCode)}`, '_self');
  };

  return (
    <Row gutter={8}>
      <Col span={12}>
        <ActionButton shape="round" onClick={handleMap}>
          <EnvironmentOutlined /> Ver mapa
        </ActionButton>
      </Col>
      <Col span={12}>
        <ActionButton shape="round" onClick={handleWhatsapp}>
          <WhatsAppOutlined /> {whatsapp}
        </ActionButton>
      </Col>
    </Row>
  );
};

const RouteDetails = () => {
  const { setTitle } = useContext(PanelContext);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [updateStoreOrder, { loading }] = useMutation(updateStoreOrderQuery);
  const deliverStoreOrder = useCallback(
    async storeOrder => {
      if (storeOrder.id) {
        await updateStoreOrder({
          variables: {
            id: storeOrder.id,
            storeOrder: {
              status: 'delivered',
            },
          },
        });
      }
    },
    [updateStoreOrder],
  );
  const {
    no_order,
    shippingAddress,
    payment_method,
    cash_payment_with,
    total,
    whatsapp,
    shipping_coordinates,
    shipping_extra,
    business,
    name,
  } = location.state.storeOrder;

  const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;

  const updateOrder = async () => {
    await deliverStoreOrder(location.state.storeOrder);
    history.push(location.pathname.replace('/detail', ''));
    postMessage('STOP_TRACKING', null);
  };

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Ya le avisamos al cliente que llegaste a su dirección',
      placement: 'bottom',
    });
  };

  const notifyCustomer = async () => {
    if (location.state.storeOrder.onesignal_player_id) {
      await driverNotifyCustomer({
        onesignal_player_id: location.state.storeOrder.onesignal_player_id,
        status: location.state.storeOrder.status,
      });
    }
    setVisible(false);
    openNotificationWithIcon('success');
  };

  // eslint-disable-next-line no-unused-vars
  const showModalTemporary = () => {
    let timeout;
    setInfoModalVisible(true);
    timeout = setTimeout(() => {
      setInfoModalVisible(false);
    }, 3000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  };

  useEffect(() => {
    setTitle('Centro de entregas');
  }, [setTitle]);

  // useEffect(showModalTemporary, []);

  return (
    <div className="mb-12">
      <Title level={4}>Entregando #{no_order}</Title>
      <RouteCard>
        <CardItem label="Nombre" info={name} />
        <CardItem label="Dirección" info={shippingAddress} />
        <CardItem label="Notas de envío" info={shipping_extra} />
        <CardItem label="Pago" info={humanizePaymentMethod(payment_method)} />
        <CardItem label="Pagará con" info={currencyFormatter(cash_payment_with)} />
        <CardItem label="Total" info={currencyFormatter(total)} />
      </RouteCard>
      <Actions
        whatsapp={whatsapp}
        shippingCoordinates={shipping_coordinates}
        countryCode={business.google_maps_country}
        hasAppOrigin={queryParams.get('origin') === 'app'}
      />
      <FloatingActionButton
        shape="round"
        size="large"
        type="secondary"
        onClick={notifyCustomer}
        style={{ bottom: 75, display: visible ? 'block' : 'none' }}
      >
        Notificar al cliente que ya llegue
      </FloatingActionButton>
      <FloatingActionButton
        loading={loading}
        shape="round"
        size="large"
        type="primary"
        onClick={updateOrder}
        style={{ bottom: 16 }}
      >
        Marcar como entregado
      </FloatingActionButton>
      <Modal visible={infoModalVisible} footer={null}>
        <Row justify="center" align="center">
          <Col span={24}>
            <Row justify="center" align="center">
              <img className="mb-4" src={DoubleLocation} alt="location-logo" />
            </Row>
          </Col>
          <Col>
            <Title level={5}>Iniciando recorrido...</Title>
          </Col>
          <Col>
            <Text>Se abrirá tu aplicación de mapas</Text>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RouteDetails;
