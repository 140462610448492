import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import { Divider } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import { ActionButton } from './DriverPanel.styles';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import Empty from 'antd/es/empty';
import PanelContext from 'context/PanelContext';
import RouteList from '../RouteList';
import RouteItem from '../RouteItem';
import { Typography } from 'ui';

const { Title } = Typography;

const createRouteQuery = loader('queries/graphql/routes/createRouteAndItems.graphql');

const RouteContainer = styled.div``;

const DriverPanel = ({ storeOrders, driver, refetch }) => {
  const [selectedStoreOrders, setSelectedStoreOrders] = useState([]);

  const { setTitle, setCurrentDriver } = useContext(PanelContext);

  const [createRoute, { loading: routeLoading }] = useMutation(createRouteQuery);
  const [createRouteLoading, setCreateRouteLoading] = useState(false);

  // Group store orders
  const filteredStoreOrders = storeOrders
    .filter(storeOrder => !selectedStoreOrders.includes(storeOrder))
    ?.sort((a, b) => moment(a?.delivery_schedule_slot?.start).unix() - moment(b?.delivery_schedule_slot?.start).unix());

  const storeOrdersByStore = groupBy(filteredStoreOrders, so => so?.store?.name || so?.business?.Name);

  Object.keys(storeOrdersByStore).forEach(k => {
    storeOrdersByStore[k] = groupBy(storeOrdersByStore[k], storeOrder => {
      const date = storeOrder.delivery_day.split(' ')[0];
      return moment(date).locale('es').format('ddd DD MMM');
    });
    Object.keys(storeOrdersByStore[k]).forEach(l => {
      storeOrdersByStore[k][l] = groupBy(storeOrdersByStore[k][l], so => so.delivery_time);
    });
  });

  const createAndAssignRoute = useCallback(async () => {
    if (!createRouteLoading) {
      setCreateRouteLoading(true);
      await createRoute({
        variables: {
          route: {
            business: driver.business?.id,
            store: selectedStoreOrders[0].store.id,
            delivery_areas: driver.delivery_areas.map(d => d.id),
            start_at: moment().toISOString(),
            driver: driver.id,
            status: 'started',
          },
          orders: selectedStoreOrders.map(so => so.id),
        },
      });

      await refetch();
      setCreateRouteLoading(false);
    }
  }, [driver, selectedStoreOrders, createRoute, refetch, createRouteLoading]);

  const toggleSelectedStoreOrder = storeOrder => {
    const isSelected = selectedStoreOrders.indexOf(storeOrder);
    if (isSelected !== -1) {
      const current = [...selectedStoreOrders];
      current.splice(isSelected, 1);
      setSelectedStoreOrders(current);
    } else {
      const current = [...selectedStoreOrders];
      current.push(storeOrder);
      setSelectedStoreOrders(current);
    }
  };

  const itemRenderer = storeOrder => {
    return <RouteItem key={storeOrder.no_order} item={storeOrder} status={storeOrder?.status} action={toggleSelectedStoreOrder} selected />;
  };

  useEffect(() => {
    setTitle('Centro de entregas');
  }, [setTitle]);

  useEffect(() => {
    if (driver) {
      setCurrentDriver(driver);
    }
  }, [setCurrentDriver, driver]);

  return (
    <div className="mb-12">
      <Title level={4}>{driver.name}</Title>
      {selectedStoreOrders.length > 0 && <Typography.Title level={4}>Pedidos seleccionados</Typography.Title>}
      <RouteContainer>{selectedStoreOrders.map(itemRenderer)}</RouteContainer>
      {selectedStoreOrders.length > 0 && <Divider />}
      {/* {Object.keys(storeOrdersByStore)?.map(
        RouteListWrapper({ orders: storeOrdersByStore, driver, action: toggleSelectedStoreOrder }),
      )} */}
      <RouteList orders={storeOrdersByStore} action={toggleSelectedStoreOrder} driver={driver} />
      {filteredStoreOrders.length === 0 && selectedStoreOrders.length === 0 && (
        <Empty description={<span>No hay pedidos listos por el momento.</span>} />
      )}
      <ActionButton
        shape="round"
        loading={routeLoading}
        size="large"
        type="primary"
        onClick={createAndAssignRoute}
        style={{ bottom: selectedStoreOrders.length > 0 ? 16 : -48 }}
      >
        Iniciar entrega ({selectedStoreOrders.length}) <RightOutlined />
      </ActionButton>
    </div>
  );
};

DriverPanel.propTypes = {
  storeOrders: PropTypes.array,
};

DriverPanel.defaultProps = {
  storeOrders: [],
};

export default DriverPanel;
