import React, { createContext, useContext, useEffect, useRef } from 'react';
import AppContext from './Context';
import { Button, notification } from 'antd';
import { DollarCircleTwoTone } from '@ant-design/icons';
import bell from '../assets/audio/bell.mp3';
import spanishOrder from '../assets/audio/tienes_una_orden.mp3';
import { buildStoreOrderNotification } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { getStoreItemsAction } from 'actions/storeItemActions';
import Pusher from 'pusher-js';
import clientAxios from '../config/axios';
import { getBaseApiUrl } from '../helpers/globals';

let bellAudio = new Audio(bell);
let spanishOrderAudio = new Audio(spanishOrder);
let unlockAudios = [bellAudio, spanishOrderAudio];

document.body.addEventListener(
  'touchstart',
  function () {
    if (unlockAudios) {
      for (let audio of unlockAudios) {
        audio.play();
        audio.pause();
        audio.currentTime = 0;
      }
      unlockAudios = null;
    }
  },
  false,
);

const WebSocketContext = createContext(null);

export { WebSocketContext };

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: 'us2',
});

/**
 * Manage the web socket connection
 * @param {Object} obj
 * @param {Function} obj.children
 */
const WebSocket = ({ children }) => {
  const {
    storefront,
    storeOrdersRefetchRef,
    storeItemsRefetchRef,
    storeOrdersRoutesRefetchRef,
    currentStore,
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const pusherChannel = useRef();

  useEffect(() => {
    if (storefront.id) {
      pusherChannel.current = pusher.subscribe(`dashboard-${storefront.id}`);

      pusherChannel.current.bind('addStoreOrder', async ({ storeOrderId }) => {
        const { data: storeOrder } = await clientAxios.get(`${getBaseApiUrl()}/store-orders/${storeOrderId}`);

        if (!currentStore || (currentStore && storeOrder.store?.id === currentStore.id)) {
          // Notificate about store order
          setTimeout(() => {
            bellAudio.onended = () => {
              spanishOrderAudio.play();
            };
            bellAudio.play();
          }, 500);

          // TODO: Add queue notifications
          if (!window.location.href.includes('/panel')) {
            notification.open({
              className: 'animate__animated animate__tada',
              message: '¡Tienes un nuevo pedido!',
              description: buildStoreOrderNotification(storeOrder),
              icon: <DollarCircleTwoTone twoToneColor="#ff339c" />,
              btn: (
                <Button type="primary" onClick={() => window.location.replace('/orders/' + storeOrder.no_order)}>
                  Ver orden
                </Button>
              ),
              duration: 10000,
            });
          }

          // Refetch store orders
          try {
            if (storeOrdersRefetchRef && typeof storeOrdersRefetchRef.current === 'function') {
              storeOrdersRefetchRef.current();
            }
            // eslint-disable-next-line no-empty
          } catch (err) {}

          try {
            if (storeOrdersRoutesRefetchRef && typeof storeOrdersRoutesRefetchRef.current === 'function') {
              storeOrdersRoutesRefetchRef.current();
            }
            // eslint-disable-next-line no-empty
          } catch (err) {}
        }
      });

      pusherChannel.current.bind('updateStoreOrder', async () => {
        // Refetch store orders
        try {
          if (storeOrdersRefetchRef && typeof storeOrdersRefetchRef.current === 'function') {
            storeOrdersRefetchRef.current();
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}

        try {
          if (storeOrdersRoutesRefetchRef && typeof storeOrdersRoutesRefetchRef.current === 'function') {
            storeOrdersRoutesRefetchRef.current();
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      });

      pusherChannel.current.bind('updateStoreItem', async () => {
        if (storeItemsRefetchRef.current) {
          dispatch(getStoreItemsAction(storeItemsRefetchRef.current));
        }
      });
    }

    return () => {
      if (storefront.id) {
        pusher.unsubscribe(`dashboard-${storefront.id}`);
        pusherChannel.current.unbind('addStoreOrder');
        pusherChannel.current.unbind('updateStoreOrder');
        pusherChannel.current.unbind('updateStoreItem');
      }
    };
  }, [storefront.id, currentStore, dispatch, storeItemsRefetchRef, storeOrdersRefetchRef, storeOrdersRoutesRefetchRef]);

  return (
    <WebSocketContext.Provider
      value={{
        pusherChannel: pusherChannel.current,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocket;
