import axios from 'axios';
import { getBaseApiUrl } from '../helpers/globals';

const clientAxios = axios.create({
  baseURL: getBaseApiUrl(),
});

clientAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && window.location.pathname !== '/auth/login') window.location = '/auth/logout';
    return Promise.reject(error);
  },
);

export default clientAxios;
