import { Select as AntSelect } from 'antd';
import styled from 'styled-components';
import { percentToHex } from 'theme';

const Select = styled(AntSelect)`
  .ant-select-selector {
    border-radius: ${props => props.theme.miscellany.borderRadius} !important;
    border: 1px solid ${props => props.theme.colors.gray} !important;
    background-color: ${props => props.theme.colors.white} !important;
    padding: 5px 10px !important;
    min-height: 44px !important;
    font-family: ${props => props.theme.typography.family};
  }
  &.ant-select-disabled {
    .ant-select-selector {
      background-color: ${props => props.theme.colors.disabled} !important;
    }
  }
  &:hover {
    .ant-select-selector {
      border-color: ${props => props.theme.colors.blue} !important;
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  &.ant-select-focused {
    .ant-select-selector {
      border-color: ${props => props.theme.colors.blue} !important;
      outline: 0 !important;
      box-shadow: 0 0 0 2px ${props => props.theme.colors.blue + percentToHex(20)} !important;
    }
  }
`;

export default Select;
