import * as React from 'react';
import Modal from './Modal';

function useModal(ModalContent, modalConfig, componentProps) {
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [props, setProps] = React.useState(componentProps);
  const [combinedModalConfig, setModalProps] = React.useState(modalConfig);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const showLoading = modalLoading => setLoading(modalLoading);

  const updateProps = incomingProps => {
    const currentProps = { ...props };
    const newProps = Object.assign({}, currentProps, incomingProps);
    setProps(newProps);
  };

  const setModalConfig = incommingModalConfigs => {
    const currentModalConfig = { ...modalConfig };
    const newModalConfig = Object.assign({}, currentModalConfig, incommingModalConfigs);
    setModalProps(newModalConfig);
  };

  const Component = (
    <Modal
      {...combinedModalConfig}
      visible={visible}
      confirmLoading={loading}
      onCancel={combinedModalConfig?.onCancel ? combinedModalConfig.onCancel : hide}
      onOk={combinedModalConfig?.onOk ? combinedModalConfig.onOk : hide}
    >
      <ModalContent {...props} />
    </Modal>
  );

  return {
    show,
    hide,
    showLoading,
    Component,
    updateProps,
    setModalConfig,
  };
}

export default useModal;
