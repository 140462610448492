import {
  SHOW_STORE_ITEM_MODAL,
  ADD_STORE_ITEM,
  ADD_STORE_ITEM_SUCCESS,
  ADD_STORE_ITEM_ERROR,
  GET_STORE_ITEMS,
  GET_STORE_ITEMS_SUCCESS,
  GET_STORE_ITEMS_ERROR,
  DELETE_STORE_ITEM,
  DELETE_STORE_ITEM_SUCCESS,
  DELETE_STORE_ITEM_ERROR,
  EDIT_STORE_ITEM,
  UPDATE_STORE_ITEM_SUCCESS,
  UPDATE_STORE_ITEM_ERROR,
  CANCEL_STORE_ITEM_MODAL,
  FILTER_STORE_ITEMS,
} from '../types';

// each reducer has it's own state
export const initialState = {
  storeItems: [],
  products: [],
  error: null,
  loading: false,
  collapse: false,
  qs: null,
  category: null,
  page: 1,
  sizePerPage: 20,
  sort: null,
  totalSize: null,
  deleteStoreItem: null,
  updateStoreItem: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_STORE_ITEM_MODAL:
      return {
        ...state,
        collapse: action.payload,
      };
    case GET_STORE_ITEMS:
      return {
        ...state,
        loading: true,
        page: action.payload.page,
        sizePerPage: action.payload.sizePerPage,
        sort: action.payload.sort ? action.payload.sort : initialState.sort,
      };
    case ADD_STORE_ITEM:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_STORE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        storeItems: [...state.storeItems, action.payload],
        collapse: false,
      };
    case ADD_STORE_ITEM_ERROR:
    case GET_STORE_ITEMS_ERROR:
    case DELETE_STORE_ITEM_ERROR:
    case UPDATE_STORE_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CANCEL_STORE_ITEM_MODAL:
      return {
        ...state,
        loading: action.payload,
        error: action.payload,
        deleteStoreItem: null,
        updateStoreItem: null,
        collapse: action.payload,
      };
    case GET_STORE_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        totalSize: action.totalSize,
        storeItems: action.payload,
        products: action.payload,
      };
    case DELETE_STORE_ITEM:
      return {
        ...state,
        deleteStoreItem: action.payload,
      };
    case DELETE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        storeItems: state.storeItems.filter(storeItem => storeItem.id !== state.deleteStoreItem),
        deleteStoreItem: null,
      };
    case EDIT_STORE_ITEM:
      return {
        ...state,
        updateStoreItem: action.payload,
      };
    case UPDATE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        updateStoreItem: null,
        storeItems: state.storeItems.map(c => (c.id === action.payload.id ? action.payload : c)),
        collapse: false,
      };
    case FILTER_STORE_ITEMS: {
      let filterTerms = {};
      Object.keys(action.payload)
        .filter(key => {
          return action.payload[key] !== undefined;
        })
        .forEach(key => {
          return (filterTerms[key] = action.payload[key]);
        });

      if ('qs' in filterTerms) filterTerms['qs'] = filterTerms['qs'].toLowerCase();

      return {
        ...state,
        ...filterTerms,
      };
    }
    default:
      return state;
  }
};
