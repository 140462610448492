import { InfoCircleTwoTone } from '@ant-design/icons';
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { ThemeProvider } from 'styled-components';
import Modal from './Modal';
import Typography from '../typography/Typography';
import Button from '../button/Button';
import theme from 'theme';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Contents = ({ title, text, icon = <InfoCircleTwoTone style={{ fontSize: 28, marginBottom: 16 }} /> }) => {
  return (
    <ContentWrapper>
      {icon}
      {title && <Typography.Title level={4}>{title}</Typography.Title>}
      <Typography.Text type="secondary">{text}</Typography.Text>
    </ContentWrapper>
  );
};

const Footer = ({ okText, cancelText, onCancel, onOk }) => {
  return (
    <FooterWrapper>
      <Button style={{ marginBottom: 8 }} type="primary" color="secondary" shape="round" size="large" onClick={onOk}>
        {okText || 'Aceptar'}
      </Button>
      <Button type="text" onClick={onCancel}>
        {cancelText || 'Cancelar'}
      </Button>
    </FooterWrapper>
  );
};

export const confirm = props => {
  const div = document.createElement('div');
  const appRoot = document.getElementById('root');
  appRoot.appendChild(div);
  let currentConfig = {
    ...props,
    visible: true,
    footer: null,
    onCancel: () => {
      if (typeof props.onCancel === 'function') {
        props.onCancel();
      }
      close();
    },
    onOk: () => {
      if (typeof props.onOk === 'function') {
        props.onOk();
      }
      close();
    },
  };

  const destroy = () => {
    const unmount = ReactDOM.unmountComponentAtNode(div);
    if (unmount && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  const render = ({ title, text, icon, okText, cancelText, onCancel, onOk, ...rest }) => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Modal {...rest}>
          <Contents title={title} text={text} icon={icon} />
          <Footer okText={okText} cancelText={cancelText} onCancel={onCancel} onOk={onOk} />
        </Modal>
      </ThemeProvider>,
      div,
    );
  };

  const close = () => {
    currentConfig = {
      ...currentConfig,
      visible: false,
      afterClose: () => {
        if (typeof props.afterClose === 'function') {
          props.afterClose();
        }
        destroy();
      },
    };
    render(currentConfig);
  };

  render(currentConfig);

  return {
    destroy: close,
  };
};
