import React from 'react';
import './assets/less/ant.less';
import './App.css';
import './index.css';
import Layout from './layouts/Layout';

import { BrowserRouter as Router } from 'react-router-dom';

const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Layout />
  </Router>
);

export default App;
