import {
  SEND_SMS_SHOW_MODAL,
  SEND_SMS_TO_USERS,
  SEND_SMS_TO_USERS_SUCCESS,
  SEND_SMS_TO_USERS_ERROR,
  SEND_SMS_CANCEL_MODAL,
} from '../types';

// each reducer has it's own state
const initialState = {
  error: null,
  loading: false,
  collapse: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_SMS_SHOW_MODAL:
      return {
        ...state,
        collapse: action.payload,
      };
    case SEND_SMS_TO_USERS:
      return {
        ...state,
        loading: action.payload,
      };
    case SEND_SMS_TO_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        collapse: false,
      };
    case SEND_SMS_TO_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_SMS_CANCEL_MODAL:
      return {
        ...state,
        loading: action.payload,
        error: action.payload,
        collapse: action.payload,
      };
    default:
      return state;
  }
};
