import { Col, Collapse } from 'antd';
import styled from 'styled-components';
import { Button, Typography } from 'ui';

export const RouteCard = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 16px; // TODO: Move this to theme
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: 6px; //TODO: move this to theme
  min-height: 150px;
  margin-bottom: 10px;
  ${Typography.Paragraph} {
    margin: 0px !important;
  }
`;

export const FlexCol = styled(Col)`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
`;

export const Info = styled(Typography.Text)`
  display: block;
  margin-bottom: 8px;
  line-height: 1.2;
  width: 100%;
`;

export const CardItemDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.gray};
  margin: 10px 0px;
`;

export const ActionButton = styled(Button)`
  width: 100% !important;
  height: 48px !important;
`;

export const Collapser = styled(Collapse)`
  width: 100%;
  .ant-collapse-header {
    padding: 0px !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
`;
