import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { Typography } from '../../../ui';
import RouteItem from './RouteItem';
import moment from 'moment';

const { Title, Text } = Typography;

const RouteListWrapper = ({ orders, action }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  const compareActualTimeWithOrder = (current, storeOrderDeliveryTime) => {
    return storeOrderDeliveryTime.diff(current, 'minutes') > 299;
  };

  const itemRenderer = storeOrder => {
    return (
      <RouteItem
        key={storeOrder.no_order}
        item={storeOrder}
        action={action}
        status={storeOrder.status}
        delivered={storeOrder.status === 'delivered'}
        disabled={compareActualTimeWithOrder(
          currentTime,
          moment(`${storeOrder.delivery_day} ${storeOrder.delivery_time?.split(' - ')[0]}`),
        )}
      />
    );
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Fragment>
      {Object.keys(orders).map(store => (
        <Fragment key={`store-${store}-list`}>
          <Row>
            <Col>
              <Title level={4}>Pedidos {store}</Title>
            </Col>
          </Row>
          {Object.keys(orders[store]).map(deliveryDate => (
            <Fragment key={`delivery-date-${store}-${deliveryDate}-list`}>
              <Row>
                <Col>
                  <Title level={5}>Entrega para {deliveryDate}</Title>
                </Col>
              </Row>
              {Object.keys(orders[store][deliveryDate]).map((deliveryTime, i) => (
                <Fragment key={`route-item-${i}`}>
                  <Row>
                    <Col>
                      <Text>{deliveryTime}</Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>{orders[store][deliveryDate][deliveryTime].map(itemRenderer)}</Col>
                  </Row>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default RouteListWrapper;
