import React from 'react';
import { Row, Col, Spin } from 'antd';

const Loading = () => (
  <Row justify="center" align="middle" className="w-full h-full">
    <Col>
      <Spin />
    </Col>
  </Row>
);

export default Loading;
