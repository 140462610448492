import styled, { css } from 'styled-components';
import { Typography as AntTypography } from 'antd';

const alignment = props => css`
  text-align: ${props.align ? props.align : 'left'} !important;
`;

const fontSize = props => css`
  font-size: ${props.fontSize ? props.fontSize : props.theme.typography.size.text};
`;

const Text = styled(AntTypography.Text)`
  font-family: ${props => props.theme.typography.family};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.text)} !important;
  ${alignment}
  ${fontSize}
`;

const Title = styled(AntTypography.Title)`
  font-family: ${props => props.theme.typography.family};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.text)} !important;
  ${alignment}
  ${fontSize}
`;

const Paragraph = styled(AntTypography.Paragraph)`
  font-family: ${props => props.theme.typography.family};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.text)} !important;
  ${alignment}
  ${fontSize}
`;

const Typography = {};

Typography.Text = Text;
Typography.Title = Title;
Typography.Paragraph = Paragraph;

export default Typography;
