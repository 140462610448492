import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import PanelLayout from './PanelLayout';

import loadable from '@loadable/component';
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));

const Layout = () => {
  useEffect(() => {
    AuthCardRoutes.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/auth" component={AuthCardRoutes} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/panel" component={PanelLayout} />
        <Route component={DashboardLayout} />
      </Switch>
    </Router>
  );
};

export default Layout;
