import React, { useEffect, useState } from 'react';
import { Typography, Form, Input } from 'antd';
import { Button } from '../../../ui';
import { useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useQueryParams from 'hooks/useQueryParams';
import postMessage from '../../../helpers/postMessage';

const findDriver = loader('queries/graphql/drivers/findDriverByToken.graphql');

const { Title } = Typography;

const DriverLogin = () => {
  const [fetchDriver, { data, loading }] = useLazyQuery(findDriver, { fetchPolicy: 'network-only' });
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const queryParams = useQueryParams();

  const onFinish = async values => {
    // findDriver
    // Success => go to driver page
    // Failed => request other number
    fetchDriver({
      variables: {
        ...values,
      },
    });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const handleChangeValues = () => {
    setHasError(false);
  };

  useEffect(() => {
    if (!loading && data && data.drivers && data.drivers.length > 0) {
      if (queryParams.get('origin') === 'app') {
        postMessage('LOGIN_SUCCESS', data.drivers[0]);
      }
      // Redirect to path
      history.push(
        `${match.path}/${data.drivers[0].whatsapp}${queryParams.get('origin') === 'app' ? '?origin=app' : ''}`,
      );
    }

    if (!loading && data && data.drivers && data.drivers.length === 0) {
      setHasError(true);
    }
  }, [data, loading, history, match, queryParams]);

  useEffect(() => {
    postMessage('LOAD_COMPLETE', true);
  }, []);

  return (
    <div>
      <Title level={4} style={{ textAlign: 'center' }}>
        Conductores
      </Title>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleChangeValues}
      >
        <Form.Item
          label="Whatsapp"
          name="whatsapp"
          rules={[
            { required: true, message: 'Ingresa tu whatsapp' },
            { pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: 'Whatsapp no válido' },
          ]}
          validateStatus={hasError ? 'error' : undefined}
          help={hasError ? 'Número no registrado, inténtalo con otro whatsapp.' : undefined}
        >
          <Input type="tel" />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" color="secondary" shape="round" block>
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DriverLogin;
