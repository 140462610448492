import { DatePicker } from 'antd';
import styled from 'styled-components';
import { percentToHex } from 'theme';

const { RangePicker: AntRangePicker } = DatePicker;

const RangePicker = styled(AntRangePicker)`
  border-radius: ${props => props.theme.miscellany.borderRadius} !important;
  border: 1px solid ${props => props.theme.colors.gray} !important;
  background-color: ${props => props.theme.colors.white} !important;
  padding: 5px 10px !important;
  min-height: 44px;
  &.ant-picker-focused,
  &:hover {
    border-color: ${props => props.theme.colors.blue} !important;
    outline: 0 !important;
    .ant-picker-active-bar {
      background: ${props => props.theme.colors.blue} !important;
    }
  }
  &:hover {
    box-shadow: none !important;
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.blue + percentToHex(20)} !important;
  }
`;

export default RangePicker;
