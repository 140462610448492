import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DriverPanelContainer from '../containers/DriverPanelContainer';
import OrdersPanel from '../components/panel/orders/OrdersPanel';
import RouteDetails from '../components/panel/routes/RouteDetails';
import DriverLogin from '../components/panel/routes/DriverLogin';

const PanelRoutes = () => (
  <Switch>
    <Route exact path="/panel" component={null} />
    <Route path="/panel/orders" component={OrdersPanel} />
    <Route exact path="/panel/driver" component={DriverLogin} />
    <Route exact path="/panel/driver/:whatsapp" component={DriverPanelContainer} />
    <Route path="/panel/driver/:whatsapp/detail" component={RouteDetails} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default PanelRoutes;
