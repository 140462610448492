import { combineReducers } from 'redux';
import categoriesReducer from './categoriesReducer';
import storeItemsReducer from './storeItemsReducer';
import storeOrdersReducer from './storeOrdersReducer';
import settingsReducer from './settingsReducer';
import smsReducer from './smsReducer';
import productsRecommendationsReducer from './productsRecommendationsReducer';

export default combineReducers({
  settings: settingsReducer,
  storeCategories: categoriesReducer,
  storeItems: storeItemsReducer,
  storeOrders: storeOrdersReducer,
  sms: smsReducer,
  productsRecommendations: productsRecommendationsReducer,
});
