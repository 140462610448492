import clientAxios from '../../config/axios';
import clientAxiosPE from '../../config/axiosPE';

export const deliver = async (params, token) => {
  return await clientAxiosPE.post(
    `/api/createBooking`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const driverNotifyCustomer = async ({ onesignal_player_id, status }) => {
  return await clientAxios.post(
    `/store-orders/driver-notify-customer`,
    { onesignal_player_id, status },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const customerHistory = async ({ business, token, whatsapp, store }) => {
  return await clientAxios.get(
    `/store-orders?business=${business}&whatsapp=${whatsapp}${store ? `&store=${store}` : ''}&_sort=created_at:DESC`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const sendWaste = async ({ no_order, token }) => {
  return await clientAxios.post(
    `/store-wastes/send-waste`,
    { no_order },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  );
};
