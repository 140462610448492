import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import 'moment/locale/es';
import locale from 'antd/es/locale/es_ES';
import { Layout, ConfigProvider, notification } from 'antd';
import NavbarTop from '../components/navbar/NavbarTop';
import Footer from '../components/footer/Footer';
import Dashboard from '../components/dashboard/Dashboard';
import AppContext from '../context/Context';
import NavbarVertical from '../components/navbar/NavbarVertical';
import { updateStore } from '../queries/strapi/storefront';
import { setItemToStore } from '../helpers/utils';
import Menu from './Menu';
import { trackWithSegment } from 'helpers/analytics';
import styled from 'styled-components';
import { useBreakpoint } from 'hooks';

const Wrapper = styled.div`
  padding: 0px 24px;
  @media screen and (min-width: 1600px) {
    width: 1400px;
    margin: 0 auto;
  }
`;

const DashboardRoutes = loadable(() => import('../routes/DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const { storefront, setStorefront, token } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onClose = () => setVisible(false);

  const showNotification = (type, message, placement = 'bottomLeft') => notification[type]({ message, placement });

  const handleProfileSettings = async e => {
    e.preventDefault();
    const storefrontUpdated = await updateStore(storefront, token);
    if (storefrontUpdated) {
      setItemToStore('o_s', storefrontUpdated);
      showNotification('info', 'Tienda actualizada correctamente');
    } else {
      showNotification('error', 'Algo salio mal, intenta de nuevo.');
    }
    // onClose();
    setIsLoading(false);
    trackWithSegment({ event: 'Profile Updated', storefront });
  };

  const toggleSider = () => {
    setCollapsed(collapsed => !collapsed);
  };

  const listenWindowChangeForSiderCollapse = () => {
    if (['xs', 'sm', 'md'].includes(breakpoint)) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(listenWindowChangeForSiderCollapse, [breakpoint]);

  return (
    <ConfigProvider locale={locale}>
      <Layout style={{ maxHeight: '100vh', minHeight: '100vh' }}>
        <NavbarVertical collapsed={collapsed} onCollapse={toggleSider} />
        <Layout
          className="bg-clear-gray"
          style={{ minWidth: !collapsed && ['xs', 'sm', 'md'].includes(breakpoint) ? '100vw' : undefined }}
        >
          <NavbarTop onCollapseSider={toggleSider} visible={visible} setVisible={setVisible} />
          <Layout.Content>
            <Wrapper>
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <DashboardRoutes />
              </Switch>
            </Wrapper>
            <Footer />
            <Menu
              onClose={onClose}
              visible={visible}
              isLoading={isLoading}
              handleProfileSettings={handleProfileSettings}
              storefront={storefront}
              setStorefront={setStorefront}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
